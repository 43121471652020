<template>
  <div class="setting">
    <header-title title="系统设置" />

    <div class="content">
      <ul>
        <li class="date">
          <p>待确认提醒日期：</p>
          <el-input-number
          :min="1"
          :max="999"
          v-model="confirmDay"
          :controls="false"
          placeholder="请输入天数"></el-input-number>
        </li>
        <li class="tips">责任编辑待确认稿件超过周期，自动发送短信和邮件。</li>
        <li class="date">
          <p>催审日期：</p>
          <el-input-number
          :min="1"
          :max="999"
          v-model="auditDay"
          :controls="false"
          placeholder="请输入天数"></el-input-number>
        </li>
        <li class="tips">指派给初审专家的审核周期，临期或超期可发送催办短信。</li>
        <li class="date">
          <p>修改日期：</p>
          <el-input-number
          :min="1"
          :max="999"
          v-model="editDay"
          :controls="false"
          placeholder="请输入天数"></el-input-number>
        </li>
        <li class="tips">打回投稿医师的修改周期，临期或超期可发送催办短信。</li>
        <li class="date">
          <p>优秀病例分数线：</p>
          <el-input-number
          :min="0"
          :max="100"
          v-model="score"
          :controls="false"
          placeholder="请输入优秀病例分数线"></el-input-number>
        </li>
        <li class="tips"></li>
        <li class="btn"><el-button type="primary" @click="setDate">确定</el-button></li>
      </ul>
    </div>
  </div>
</template>

<script>
import HeaderTitle from '../../components/HeaderTitle.vue'
import { getSysDate, setSysDate } from '@/api/auth'

export default {
  name: 'Setting',
  components: { HeaderTitle },
  data() {
    return {
      confirmDay: '',
      auditDay: '',
      editDay: '',
      score: null,
      id: ''
    }
  },
  created () {
    this.getDate()
  },
	methods: {
    // 获取系统时间
    async getDate() {
      const res = await getSysDate()
      if(res.code === 0) {
        this.confirmDay = res.data.confirmTime
        this.auditDay = res.data.auditTime
        this.editDay = res.data.updateTime
        this.score = res.data.score
        this.id = res.data.id
      }
    },

    // 设置系统时间
    async setDate() {
      const params = {
        confirmTime: this.confirmDay,
        auditTime: this.auditDay,
        updateTime: this.editDay,
        score: this.score,
        id: this.id
      }
      if(!this.confirmDay){
        this.$message({type: 'error', message: '请设置待确认提醒日期', customClass: 'zindex-mess'})
        return false
      }
      if(!this.auditDay){
        this.$message({type: 'error', message: '请设置催审日期', customClass: 'zindex-mess'})
        return false
      }
      if(!this.editDay){
        this.$message({type: 'error', message: '请设置修改日期', customClass: 'zindex-mess'})
        return false
      }
      if(!this.score){
        this.$message({type: 'error', message: '请设置优秀病例分数线', customClass: 'zindex-mess'})
        return false
      }
      const res = await setSysDate(params)
      if(res.code === 0) {
        this.$message({type: 'success', message: '设置成功', customClass: 'zindex-mess'})
        this.getDate()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.setting {
  width: 100%;
  height: 100%;
}

.content {
  width: 100%;
  margin-top: 32px;
  padding: 100px 0 50px;
  background-color: #fff;
}
ul {
  width: 600px;
  margin: 0 auto;
  font-size: 16px;

  .date {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    p {
      min-width: 140px;
    }
  }

  .tips {
    padding-left: 100px;
    margin-bottom: 50px;
  }

  .btn {
    width: 100%;
    text-align: center;
    .el-button {
      width: 100px;
    }
  }
}
</style>
