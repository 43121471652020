<template>
  <div class="edit-detail">
    <header-title title="责任编辑详情" />
    <div class="back">
      <el-button type="primary" @click="callBack">返回</el-button>
    </div>
    <user-info title="责任编辑信息" :info="userInfo" :checkUserRole="3" />
    <audit-list :userId="userId" :userRole="userRole" />
  </div>
</template>

<script>
import HeaderTitle from '../../components/HeaderTitle.vue'
import UserInfo from '../../components/user/UserInfo.vue'
import AuditList from '../../components/user/AuditList.vue'
import { getUserDetail } from '@/api/user'

export default {
  name: 'EditDetail',
  components: { HeaderTitle, UserInfo, AuditList },
  data() {
    return {
      userInfo: {},
      userId: '',
      userRole: '',
    }
  },
  created () {
    this.userRole = sessionStorage.getItem('userRole')
    const query = this.$route.query;
    this.userId = query.userId
    if(query.userId) {
      this.getUserDetail(query.userId)
    }
  },
  methods: {
    // 获取用户信息
    async getUserDetail(id) {
      const res = await getUserDetail({id})
      if(res.code === 0) {
        this.userInfo = res.data
      }
    },

    // 返回上一级
    callBack() { this.$router.go(-1) }
  }
}
</script>

<style lang="less" scoped>
.edit-detail {
  width: 100%;
  height: 100%;
  position: relative;
}

.back {
  position: absolute;
  top: 20px;
  right: 20px;
}
</style>
