<template>
	<div :class="border ? 'header-title line' : 'header-title'">
		<p class="point"><span></span></p>
		<p class="title">{{title}}</p>
	</div>
</template>

<script>
export default {
  name: 'HeaderTitle',
  props: {
    title: {
      type: String,
      default: ''
		},
		border: {
      type: Boolean,
      default: false
		}
  },
}
</script>

<style lang="less" scoped>
.header-title {
	width: 100%;
	height: 80px;
	background-color: #fff;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	padding-left: 20px;
	font-size: 16px;
	font-family: PingFangSC, PingFangSC-Regular;
	color: #0b1e3b;
	font-weight: 600;

	.point {
		width: 16px;
		height: 16px;
		border-radius: 50%;
		background-color: #4A79FB;
		position: relative;
		margin-right: 10px;
		
		span {
			display: inline-block;
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background-color: #fff;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
}
.line  {
	border-bottom: 1px solid #E6EAF2;
}
</style>