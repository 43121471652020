<template>
  <div class="admin-detail">
    <header-title title="领域管理员详情" />
    <div class="back">
      <el-button type="primary" @click="callBack">返回</el-button>
    </div>
    <user-info title="领域管理员信息" :info="userInfo" :checkUserRole="2" />
  </div>
</template>

<script>
import HeaderTitle from '../../components/HeaderTitle.vue'
import UserInfo from '../../components/user/UserInfo.vue'
import { getUserDetail } from '@/api/user'

export default {
  name: 'AdminDetail',
  components: { HeaderTitle, UserInfo },
  data() {
    return {
      userInfo: {},
      userId: '',
      userRole: ''
    }
  },
  created () {
    this.userRole = sessionStorage.getItem('userRole')
    const query = this.$route.query;
    this.userId = query.userId
    if(query.userId) {
      this.getUserDetail(query.userId)
    }
  },
  methods: {
    // 获取用户信息
    async getUserDetail(id) {
      const res = await getUserDetail({id})
      if(res.code === 0) {
        this.userInfo = res.data
      }
    },
		// 返回上一级
    callBack() { this.$router.go(-1) }
  }
}
</script>

<style lang="less" scoped>
.admin-detail {
  width: 100%;
  height: 100%;
	position: relative;
}
.back {
  position: absolute;
  top: 20px;
  right: 20px;
}
</style>
