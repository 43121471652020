<template>
  <div class="edit">
    <!-- 筛选 -->
    <div class="header-search">
      <el-input v-model="name" placeholder="名称模糊搜索"></el-input>
      <el-select v-model="classId" placeholder="请选择关联专科">
        <el-option
          v-for="item in classOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
      <el-button type="primary" icon="el-icon-search" @click="search">筛选</el-button>
      <el-button type="danger" icon="el-icon-brush" @click="refresh">清除</el-button>
      <el-button 
      class="add-btn" 
      type="primary" 
      icon="el-icon-plus" 
      @click="EditAdd(0)">
        新增责任编辑
      </el-button>
    </div>
    <!-- 有数据 -->
    <template v-if="tableData.length">
      <!-- 列表 -->
      <div class="content-table">
        <el-table :data="tableData" border stripe style="width: 100%">
          <el-table-column label="序号" align="center" width="100">
            <template slot-scope="scope">{{ getNumId(scope.$index) }}</template>
          </el-table-column>
          <el-table-column prop="name" label="名称" align="center" />
          <el-table-column prop="hosName" label="机构" width="250" />
          <el-table-column prop="className" label="关联专科" align="center" />
          <el-table-column label="稿件" align="center" width="350">
            <template slot-scope="scope">
              <span>{{scope.row.total}}（
                在审：{{scope.row.pendingNum}}
                初审完成：{{scope.row.firstAuditNum}}
                终审完成：{{scope.row.lastAuditNum}}
                退稿：{{scope.row.refuseNum}}）
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="入驻时间" align="center" />
          <el-table-column label="状态" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.isDelete ? '不可用' :'可用'}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="150" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="openDetail(scope.row.id)">详情</el-button>
              <el-button type="text" @click="EditAdd(1, scope.row.id)">编辑</el-button>
              <el-button type="text" @click="openModal(scope.row)">状态</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="footer-page">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="pageChange"
          :total="total"
          :page-size="10"
          :current-page="page">
        </el-pagination>
      </div>
    </template>
    <!-- 无数据 -->
    <template v-else>
      <div class="empty"><el-empty :image-size="200"></el-empty></div>
    </template>
    <!-- 状态弹窗 -->
    <state-modal
    :visible="visible" :info="editInfo" :page="page"
    @close="closeModal" @getData="getUserList" />
  </div>
</template>

<script>
import StateModal from '../../components/user/StateModal.vue'
import { getUserList, getClassification } from '@/api/user'

export default {
  name: 'Edit',
  components: { StateModal },
  data() {
    return {
      // 基础数据
      name: '',
      classId: '',
      classOptions: [],
      tableData: [],
      total: 0,
      page: 1,
      // 编辑状态
      visible: false,
      editInfo: {}
    }
  },
  created () {
    this.getClassification()
    this.getUserList(1)
  },
  methods: {
    // 获取责任编辑列表
    async getUserList(page) {
      const params = {
        role: 3,
        pageNum: page,
        pageSize: 10,
      }
      if(this.name) { params.name = this.name }
      if(this.classId) { params.classId = this.classId }
      console.log('\n\n---params---', params)
      const res = await getUserList(params)
      if(res.code === 0) {
        const resData = res.data || {}
        this.tableData = resData.rows || []
        this.total = resData.total || 0
        this.page = page
      }
    },
    // 获取关联领域
    async getClassification() {
      const res = await getClassification()
      if(res.code === 0) {
        this.classOptions = res.data
      }
    },
    // 筛选
    search() {
      this.getUserList(1)
    },
    // 清除筛选
    refresh() {
      this.name = ""
      this.classId = ""
      this.getUserList(1)
    },
    // 分页
    pageChange(page) {
      this.getUserList(page)
    },
    // 获取序号
    getNumId(index) {
      const num1 = index + 1
      const num2 = (this.page - 1) * 10
      return Number(num1 + num2)
    },
    // 打开 状态编辑弹窗
    openModal(info) {
      this.visible = true
      this.editInfo = info
    },
    // 关闭 状态编辑弹窗
    closeModal() {
      this.visible = false
      this.editInfo = {}
    },
    // 新建、修改 责任编辑
    EditAdd(type, id) {
      this.$router.push({
        path: '/edit-add',
        query: { userId: id, type }
      });
    },
    // 查看详情
    openDetail(id) {
      this.$router.push({
        path: '/edit-detail',
        query: { userId: id }
      });
    }
  }
}
</script>

<style lang="less" scoped>
.edit {
  width: 100%;
  height: 100%;
}

.empty {
  width: 100%;
	margin-top: 32px;
  background-color: #fff;
}
</style>
