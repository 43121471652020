<template>
  <div class="check-list">
    <!-- 筛选 -->
    <div class="header-search">
      <el-input v-model="title" placeholder="标题模糊搜索"></el-input>
      <el-input v-model="creatorName" placeholder="投稿医生"></el-input>
      <el-select v-model="editorId" placeholder="请选择责任编辑" v-if="userRole != 3 && userRole != 4">
        <el-option
          v-for="item in editOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
      <el-select v-model="state" placeholder="请选择状态" v-if="userRole != 4">
        <el-option
          v-for="item in stateOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-button type="primary" icon="el-icon-search" @click="search">筛选</el-button>
      <el-button type="danger" icon="el-icon-brush" @click="refresh">清除</el-button>
      <el-button 
      type="success" 
      v-if="userRole != 4" 
      @click="multipleExport" 
      style="width: 120px;"
      :loading="exportLoading">批量导出</el-button>
    </div>

    <!-- 有数据 -->
    <template v-if="tableData.length">
      <!-- 审核数据 -->
      <state-count :count="count" v-if="userRole != 4" />
      <!-- 列表 -->
      <div class="content-table">
        <el-table 
        ref="multipleTable"
        :data="tableData" 
        border stripe 
        @selection-change="handleSelectionChange"
        style="width: 100%">
          <el-table-column type="selection" width="55" v-if="userRole != 4"></el-table-column>
          <el-table-column label="序号" align="center" width="80">
            <template slot-scope="scope">
              <span :class="urgeDate(scope.row.state, scope.row.updateTime).className">{{ getNumId(scope.$index) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="申请编号" align="center" width="120">
            <template slot-scope="scope">
              <span :class="urgeDate(scope.row.state, scope.row.updateTime).className">{{scope.row.uid}}</span>
            </template>
          </el-table-column>
          <el-table-column label="标题" width="180px">
            <template slot="header">
              <span>标题</span>
              <el-tooltip placement="top">
                <div slot="content">优秀稿件是指某一个稿件的由初审专家打分的《临床案例评分标准》平均分达到{{ score }}分以上。</div>
                <span style="padding-left: 5px"><i class="el-icon-medal" style="color: #ff0000;font-size: 18px; "></i>什么是优秀稿件</span>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              <span :class="urgeDate(scope.row.state, scope.row.updateTime).className">
                <i class="el-icon-medal" style="color: #ff0000;" v-if="scope.row.isExcellent === 1"></i>
                {{scope.row.title}}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="投稿医师" align="center" v-if="userRole != 4">
            <template slot-scope="scope">
              <span :class="urgeDate(scope.row.state, scope.row.updateTime).className">{{scope.row.creatorName}}</span>
            </template>
          </el-table-column>
          <el-table-column label="责任编辑" align="center">
            <template slot-scope="scope">
              <span :class="urgeDate(scope.row.state, scope.row.updateTime).className">{{scope.row.editorName}}</span>
            </template>
          </el-table-column>
          <el-table-column label="初审专家" align="center" v-if="userRole != 4">
            <template slot-scope="scope">
              <span :class="urgeDate(scope.row.state, scope.row.updateTime).className">{{scope.row.expertName}}</span>
            </template>
          </el-table-column>
          <el-table-column label="状态" align="center" v-if="userRole != 4" width="100">
            <template slot-scope="scope">
              <span :class="urgeDate(scope.row.state, scope.row.updateTime).className">{{stateObj[scope.row.state]}}</span>
            </template>
          </el-table-column>
          <el-table-column label="更新时间" align="center" v-if="userRole != 4">
            <template slot-scope="scope">
              <span :class="urgeDate(scope.row.state, scope.row.updateTime).className">{{scope.row.updateTime}}</span>
            </template>
          </el-table-column>
          <el-table-column label="投稿时间" align="center" v-if="userRole == 4">
            <template slot-scope="scope">
              <span :class="urgeDate(scope.row.state, scope.row.updateTime).className">{{scope.row.createTime}}</span>
            </template>
          </el-table-column>
          <el-table-column label="截稿日期" align="center">
            <template slot-scope="scope"
            v-if="(scope.row.state == 5 || scope.row.state == 10)">
              <p :class="urgeDate(scope.row.state, scope.row.updateTime).className">
                {{urgeDate(scope.row.state, scope.row.updateTime).urgeDate}}
              </p>
              <p :class="urgeDate(scope.row.state, scope.row.updateTime).className">
                {{urgeDate(scope.row.state, scope.row.updateTime).day}}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120" align="center">
            <template slot-scope="scope">
              <template v-if="userRole == 4">
                <el-button type="text" 
                v-if="scope.row.state === 10" 
                @click="openDetail(scope.row.id, true)">
                  审批
                </el-button>
                <el-button type="text"
                v-else-if="scope.row.state === 0"  
                @click="openDetail(scope.row.id, true)">
                  修改
                </el-button>
                <el-button type="text"
                v-else 
                @click="openDetail(scope.row.id, false)">
                  查看
                </el-button>
              </template>
              <template v-else-if="userRole == 3">
                <el-button type="text" 
                @click="openDetail(scope.row.id, true)">
                查看
                </el-button>
                <el-button type="text" 
                v-if="scope.row.state === 5 || scope.row.state === 10" 
                @click="urgeAudit(scope.row.id)">
                  催办
                </el-button>
                <el-button type="text"
                v-if="scope.row.state === 11"
                @click="showModal(scope.row.id)">
                  终审
                </el-button>
                <el-button type="text"
                v-if="scope.row.state === 20"
                @click="download(scope.row.certificate)">
                  证书
                </el-button>
                <el-button type="text" @click="oneExport(scope.row)">
                  导出
                </el-button> 
              </template>
              <template v-else>
                <el-button type="text" 
                @click="openDetail(scope.row.id, false)">
                  查看
                </el-button>
                <el-button type="text" @click="oneExport(scope.row)">
                  导出
                </el-button> 
              </template>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="footer-page">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="pageChange"
          :total="total"
          :page-size="5"
          :current-page="page">
        </el-pagination>
      </div>
    </template>

    <!-- 无数据 -->
    <template v-else>
      <div class="empty"><el-empty :image-size="200"></el-empty></div>
    </template>

    <!-- 终审弹窗 -->
    <el-dialog
    class="modal"
    :visible="visible"
    width="650px"
    @close="closeModal(false)"
    :footer="null">
      <h2 class="title">终审结果</h2>
      <div class="state">
        <!-- <el-radio v-model="radio" border label="16">终审未通过</el-radio> -->
        <el-radio v-model="radio" border label="5">待修改</el-radio>
        <el-radio v-model="radio" border label="20">终审通过</el-radio>
      </div>
      <div class="upload" v-if="radio == 20">
        <p>上传证书：</p>
        <div class="upload-btn">
          <el-button
          v-if="!image" size="small" type="primary"
          @click="uploadImg"
          :loading="loading">
          上传证书
          </el-button>
          <el-button
          v-else size="small" type="primary"
          @click="uploadImg"
          :loading="loading">
          替换证书
          </el-button>
          <p>可上传jpg、jpeg、png，不超过5M</p>
        </div>
      </div>
      <div class="upload" v-else></div>
      <div class="btn-div"><el-button class="btn" type="primary" @click="lastAudit">确定</el-button></div>
    </el-dialog>

    <el-upload
      ref="upload"
      :http-request="upload"
      :show-file-list="false"
      multiple
      action
      style="display: none"
    >
    </el-upload>
  </div>
</template>

<script>
import reqwest from "reqwest";
import moment from 'moment/moment'
import StateCount from '../../components/StateCount.vue'
import { upload, getSysDate } from '@/api/auth'
import { getUserData } from '@/api/user'
import { getCheckList, assign, urgeUser, exportContent, batchExport } from '@/api/case'


export default {
  name: 'CheckList',
  components: { StateCount },
  data() {
    return {
      // 基础数据
      userRole: '', // 1: 超级管理员、2：领域管理员、3：责任编辑、4：初审专家
      auditTime: '',
      editTime: '',
      userClassId: '',
      tableData: [],
      count: {},
      stateObj: {
        '0': '待确认',
        '5': '待修改',
        '10': '待审核',
        '11': '初审通过',
        '15': '退稿',
        '16': '终审不通过',
        '20': '终审通过',
      },
      total: 0,
      page: 1,
      // 筛选数据
      title: '',
      creatorName: '',
      editorId: '',
      state: '',
      editOptions: [],
      stateOptions: [{
        value: '0',
        label: '待确认'
      },{
        value: '5',
        label: '待修改'
      },{
        value: '10',
        label: '待审核'
      },{
        value: '11',
        label: '初审通过'
      },{
        value: '15',
        label: '退稿'
      },{
        value: '16',
        label: '终审不通过'
      },{
        value: '20',
        label: '终审通过'
      }],
      // 终审
      visible: false,
      radio: '',
      image: '',
      loading: false,
      score: 85, // 分数线
      contentId: '',
      multipleSelection: [], // 表格多选
      exportLoading: false,
    }
  },
  created () {
    this.userRole = sessionStorage.getItem('userRole')
    this.userClassId = sessionStorage.getItem('userClassId')
    this.auditTime = sessionStorage.getItem('auditDate')
    this.editTime = sessionStorage.getItem('editDate')
    this.getSysDate()
    this.getEditData()
    this.getData(1)
  },
  methods: {
    // 获取系统时间
    async getSysDate() {
      const res = await getSysDate()
      if(res.code === 0) {
        this.score = res.data.score
      }
    },
    // 获取责任编辑列表
    async getEditData() {
      const params = {
        classId: this.userClassId,
        role: 3
      }
      const res = await getUserData(params)
      if(res.code === 0) {
        this.editOptions = res.data
      }
    },
    // 获取审批数据
    async getData(page) {
      const { title, creatorName, editorId, state } = this
      const params = { pageNum: page, pageSize: 5 }
      if(title) { params.title = title }
      if(creatorName) { params.creatorName = creatorName }
      if(editorId) { params.editorId = editorId }
      if(state) { params.state = state }
      const res = await getCheckList(params)
      if(res.code === 0) {
        const resData = res.data || {}
        const list = resData.list || {}
        this.tableData = list.rows || []
        this.total = list.total || 0
        this.page = page
        this.count = {
          pendingNum: resData.pendingNum || 0,
          firstAuditNum: resData.firstAuditNum || 0,
          lastAuditRejectNum: resData.lastAuditRejectNum || 0,
          lastAuditPassNum: resData.lastAuditPassNum || 0,
          refuseNum: resData.refuseNum || 0,
        }
      }
    },
    // 筛选
    search() { this.getData(1) },
    // 清除筛选
    refresh() {
      this.title = ''
      this.creatorName = ''
      this.editorId = ''
      this.state = ''
      this.getData(1)
    },
    // 分页
    pageChange(page) {
      this.getData(page)
    },
    // 获取序号
    getNumId(index) {
      const num1 = index + 1
      const num2 = (this.page - 1) * 5
      return Number(num1 + num2)
    },
    // 获取催办时间
    urgeDate(state, date) {
      const addDay = state === 5 ? this.editTime : this.auditTime
      const timer = this.transformDate(date)
      let timeStamp = moment(timer).valueOf()
      timeStamp += (86400000 * addDay) // +n天
      const _date = this.transformDate(timeStamp)
      const year = _date.getFullYear()
      let month = _date.getMonth() + 1
      month = month < 10 ? ('0' + month) : month
      let day = Number(_date.getDate())
      day = day < 10 ? ('0' + day) : day
      const urgeDate = year + '/' + month + '/' + day + ' 23:59:59'
      const dayObj = this.getDiffDay(urgeDate)
      let className = ''
      if((state == 5 || state == 10) && date) {
        className = (dayObj.type && dayObj.totalDay< 4) || !dayObj.type ? 'actve' : ''
      }
      const dayStr = !dayObj.type ? `(超期${dayObj.totalDay}天)` : `(剩余${dayObj.totalDay}天)`
      const obj = { urgeDate, day: dayStr, className }
      return obj
    },
    // 距离催办时间天数
    getDiffDay(urgeDate) {
      const currDate = Date.now()
      const timer = this.transformDate(urgeDate)
      const date = moment(timer).valueOf()
      const diffDay = Math.abs(date - currDate)
      let totalDay
      let type
      if(date >= currDate) {
        type = true
        totalDay = Math.floor(diffDay / (3600 * 24 * 1e3));
      } else {
        type = false
        totalDay = Math.ceil(diffDay / (3600 * 24 * 1e3));
      }
      const obj = { type, totalDay }
      return obj
    },
    transformDate(t) {
      const date = moment(t).toDate()
      return date
    },
    // 催办
    async urgeAudit(id) {
      const params = { contentId: id }
      const res = await urgeUser(params)
      if(res.code === 0) {
        this.getData(this.page)
        this.$message({type: 'success', message: '催办成功', customClass: 'zindex-mess'})
      } else {
        this.$message({type: 'error', message: res.msg || '催办失败', customClass: 'zindex-mess'})
      }
    },
    // 终审弹窗
    showModal(id) {
      this.visible = true
      this.contentId = id
    },
    closeModal() {
      this.visible = false
      this.radio = ''
      this.image = ''
      this.loading = false
      this.contentId = ''
    },
    // 上传证书
    uploadImg() { this.$refs["upload"].$children[0].$refs.input.click(); },
    upload(params) {
      this.loading = true
      const that = this
      const file = params.file
      const verifyList = [
        {
          text: "格式错误，请上传jpg、png、jpeg格式文件",
          result: file.type.includes('image'),
        },
        {
          text: "图片大小超过5M",
          result: (file.size / 1024 / 1024) < 5,
        }
      ];
      for (let item of verifyList) {
        if (!item.result) {
          this.$message({type: 'error', message: item.text, customClass: 'zindex-mess'})
          this.loading = false
          return;
        }
      }
      const form = new FormData();
      form.append("file", file);
      form.append("clientType", "multipart/form-data");
      reqwest({
        url: upload.action,
        method: "post",
        processData: false,
        withCredentials: true,
        data: form,
        success: (res) => {
          if (res.code !== 0) {
            this.$message({type: 'error', message: res.msg || "请重新上传", customClass: 'zindex-mess'})
            that.loading = false
            return;
          }
          that.loading = false
          that.image = res.data && res.data.url
          this.$message({type: 'success', message: '上传成功', customClass: 'zindex-mess'})
        },
      });
    },
    // 终审
    async lastAudit() {
      const params = {
        state: Number(this.radio),
        id: this.contentId
      }
      if(this.radio+'' === '20') {
        if(!this.image) {
          this.$message({type: 'error', message: '请上传证书', customClass: 'zindex-mess'})
          return false
        }
        params.certificate = this.image
      }
      const res = await assign(params)
      if(res.code === 0) {
        this.$message({type: 'success', message: '终审完成', customClass: 'zindex-mess'})
        this.getData(this.page)
        this.closeModal()
      }
    },
    // 下载证书
    download(href) {
      let image = new Image();
      image.src = href;
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "Anonymous"); // 支持跨域
      image.onload = function() {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = "证书"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      }
    },
    // 查看\审批
    openDetail(id, state) {
      this.$router.push({
        path: '/check-detail',
        query: { id, isShowCheck: state }
      });
    },
    // 表格多选
    handleSelectionChange(val) { this.multipleSelection = val; },
    // 批量导出
    async multipleExport() {
      const { multipleSelection } = this
      if(!multipleSelection.length) {
        this.$message({type: 'error', message: '请选择稿件', customClass: 'zindex-mess'})
        return false
      }
      this.exportLoading = true
      const contentIdArr = []
      multipleSelection.forEach(item => {
        contentIdArr.push(item.id)
      })
      const params = {
        contentIds: contentIdArr.join(",")
      }
      const res = await batchExport(params)
      if(res.code === 0) {
        window.open(res.data);
        this.exportLoading = false
      } else {
        this.exportLoading = false
         this.$message({type: 'error', message: '导出数据错误', customClass: 'zindex-mess'})
      }
    },
    // 导出
    async oneExport(val) {
      const res = await exportContent({ contentId: val.id})
      if(res.code === 0) {
        window.open(res.data);
      } else {
         this.$message({type: 'error', message: '导出数据错误', customClass: 'zindex-mess'})
      }
    }
  }
}
</script>

<style lang="less" scoped>
.check-list {
  width: 100%;
  height: 100%;
}

.empty {
  width: 100%;
	margin-top: 32px;
  background-color: #fff;
}

.actve {
  color: #FF3F3F;
}

.modal {
  text-align: center;

  .title {
    margin-bottom: 50px;
  }

  .state {
    text-align: center;
    margin-bottom: 15px;

    .el-radio {
      width: 120px;
      margin-left: 30px;
      margin-right: 30px;
    }
  }

  .upload {
    height: 60px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 30px;
    padding-top: 5px;
    box-sizing: border-box;
    font-size: 16px;

    .upload-btn {
      text-align: left;

      p {
        font-size: 14px;
        color: #888;
        line-height: 30px;
      }
    }
  }

  .btn-div {
    width: 100%;
    height: 100px;
    .btn {
      width: 150px;
      margin-bottom: 0 auto;
    }
  }

}
</style>
