export const scoreTableList = [
  {
    title: "科学性（60分）",
    rowspan: 6,
    key: "scienceOne",
    subfactor: "病史的全面性",
    fullScore: 10,
    score: 0
  },
  {
    subfactor: "检查的必要性（包括影像、病理及实验室检查）",
    key: "scienceTwo",
    fullScore: 10,
    className: "active",
    score: 0
  },
  {
    subfactor: "诊断、鉴别诊断的正确性和全面性",
    key: "scienceThree",
    fullScore: 10,
    score: 0
  },
  {
    subfactor:
      "治疗方法的正确科学、规范性（可包括药物不良反应、围手术期的处理等）",
    key: "scienceFour",
    fullScore: 10,
    className: "active",
    score: 0
  },
  {
    subfactor: "临床思维的逻辑性和讨论的全面性（讨论全面，应包括经验教训分享）",
    key: "scienceFive",
    fullScore: 10,
    score: 0
  },
  {
    subfactor: "对该疾病国内外学术动态的了解程度(引用文献数量、出版时间)",
    key: "scienceSix",
    fullScore: 10,
    className: "active",
    score: 0
  },
  {
    title: "实用性（25分）",
    rowspan: 3,
    key: "practicabilityOne",
    subfactor: "对提高疾病的认识有帮助",
    fullScore: 10,
    score: 0
  },
  {
    subfactor: "诊断和/或治疗疾病的方法先进，有推广价值，或对临床诊治有范例作用",
    key: "practicabilityTwo",
    fullScore: 10,
    className: "active",
    score: 0
  },
  {
    subfactor: "有长远或潜在的研究价值",
    key: "practicabilityThree",
    fullScore: 5,
    score: 0
  },
  {
    title: "规范性（15分）",
    rowspan: 2,
    subfactor: "写作水平（文题准确、结构完整、行文流畅）",
    key: "normativeOne",
    fullScore: 10,
    className: "active",
    score: 0
  },
  {
    subfactor: "图片清晰典型、表格规范精炼",
    key: "normativeTwo",
    fullScore: 5,
    score: 0
  },
  {
    subfactor: "合计",
    colspan: 3,
    key: "totalPoints",
    score: 0
  }
];
