import Vue from 'vue'
import { MENUS, TOPMENU } from '../mutation-types'

const permission = {
  namespaced: true,
  state: {
    access: [],
    menus: [],
    topMenu: []
  },

  mutations: {
    SET_MENUS (state, menus) {
      Vue.ss.set(MENUS, menus)
      state.menus = menus
    },
    SET_TOPMENUS (state, topMenu) {
      Vue.ss.set(TOPMENU, topMenu)
      console.log(topMenu)
      state.topMenu = topMenu
    }
  }
}

export default permission
