import Login from '../views/Login.vue'
import Index from '../views/Index.vue'
import Home from '../views/Home.vue'
import Error from '../views/404.vue'
// 用户
import Audit from '../views/user/Audit.vue'
import AuditAdd from '../views/user/AuditAdd.vue'
import AuditDetail from '../views/user/AuditDetail.vue'
import Edit from '../views/user/Edit.vue'
import EditAdd from '../views/user/EditAdd.vue'
import EditDetail from '../views/user/EditDetail.vue'
import Admin from '../views/user/Admin.vue'
import AdminAdd from '../views/user/AdminAdd.vue'
import AdminDetail from '../views/user/AdminDetail.vue'
// 审批
import CheckList from '../views/case/CheckList.vue'
import CheckDetail from '../views/case/CheckDetail.vue'
// 系统
import Personal from '../views/system/Personal.vue'
import Setting from '../views/system/Setting.vue'


const routes = [
	{
		path: '/login',
		name: 'login',
		component: Login
	},
	{
		path: '/',
		name: '',
		component: Index,
		meta: {
			requireAuth: true,
		},
		children: [
			{
				path: '/home',
				name: 'home',
				component: Home,
				meta: {
					requireAuth: true,
				}
			},
			{
				path: '/audit',
				name: 'audit',
				component: Audit,
				meta: {
					requireAuth: true,
				}
			},
			{
				path: '/audit-add',
				name: 'audit-add',
				component: AuditAdd,
				meta: {
					requireAuth: true,
				}
			},
			{
				path: '/audit-detail',
				name: 'audit-detail',
				component: AuditDetail,
				meta: {
					requireAuth: true,
				}
			},
			{
				path: '/edit',
				name: 'edit',
				component: Edit,
				meta: {
					requireAuth: true,
				}
			},
			{
				path: '/edit-add',
				name: 'edit-add',
				component: EditAdd,
				meta: {
					requireAuth: true,
				}
			},
			{
				path: '/edit-detail',
				name: 'edit-detail',
				component: EditDetail,
				meta: {
					requireAuth: true,
				}
			},
			{
				path: '/admin',
				name: 'admin',
				component: Admin,
				meta: {
					requireAuth: true,
				}
			},
			{
				path: '/admin-add',
				name: 'admin-add',
				component: AdminAdd,
				meta: {
					requireAuth: true,
				}
			},
			{
				path: '/admin-detail',
				name: 'admin-detail',
				component: AdminDetail,
				meta: {
					requireAuth: true,
				}
			},
			{
				path: '/check-list',
				name: 'check-list',
				component: CheckList,
				meta: {
					requireAuth: true,
				}
			},
			{
				path: '/check-detail',
				name: 'check-detail',
				component: CheckDetail,
				meta: {
					requireAuth: true,
				}
			},
			{
				path: '/personal',
				name: 'personal',
				component: Personal,
				meta: {
					requireAuth: true,
				}
			},
			{
				path: '/setting',
				name: 'setting',
				component: Setting,
				meta: {
					requireAuth: true,
				}
			},
		]
	},
	{
		path: '/404',
		name: '404',
		component: Error
	}
]

export default routes
