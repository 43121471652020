<template>
  <div class="home">
    <!-- <h2>欢迎登录审稿系统</h2> -->
    <h2>欢迎登录临床案例评审系统</h2>
  </div>
</template>

<script>
export default {
  name: 'Home',
}
</script>

<style lang="less" scoped>
.home {
  width: 100%;
  height: 100%;
  padding-top: 200px;
  box-sizing: border-box;
  background-color: #fff;

  h2 {
    width: max-content;
    margin: 0 auto;
    color: #2D68EC;
    font-size: 30px;
  }
}
</style>
