<template>
  <el-container class="index-container">
    <!-- 头部 -->
    <el-header>
      <!-- <img alt="" src="../assets/image/title.png" /> -->
      <h2 style="color:#fff;">临床案例评审系统</h2>
      <div @click="logout">
        <img alt="" src="../assets/image/logout.png" />
        <p>退出登录</p>
      </div>
    </el-header>

    <el-container class="cont">
      <!-- 菜单 -->
      <el-aside width="240px">
        <el-menu
        background-color="#fff"
        text-color="#52596f"
        active-text-color="#2D68EC"
        :default-active="active">
          <el-submenu
          v-for="item in menuList"
          :key="item.id"
          :index="item.id+''">
            <template slot="title">
              <i :class="iconsObj[item.id]"></i>
              <span>{{item.name}}</span>
            </template>

            <el-menu-item
            v-for="subItem in item.children"
            :index="subItem.id+''"
            :key="subItem.id"
            @click="saveNavState(subItem.id, subItem.url)">
              <template slot="title">
                <span>{{subItem.name}}</span>
              </template>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <!-- 主体 -->
      <el-main><router-view /></el-main>
    </el-container>
  </el-container>
</template>

<script>
import { mapActions } from 'vuex'
import Vue from 'vue'
import { ACCESS_TOKEN } from '@/store/mutation-types'

export default {
  name: 'Index',
  data() {
    return {
      menuList: [], //左侧菜单数据
      active: '', //被激活的来链接地址
      iconsObj: {
        '1': 'el-icon-user',
        '5': 'el-icon-trophy',
        '7': 'el-icon-setting',
      }, //菜单icon
    }
  },
  created () {
    const login = sessionStorage.getItem('login')
    this.active = sessionStorage.getItem('menuSelectedKeys')
    if (!login) {
      Vue.ss.remove(ACCESS_TOKEN)
      this.$router.push('/login')
    }
    this.getMenuList()
  },
  watch: {
    $route(to, from) {
      if(to.path == '/' || to.path == '/home') {
        this.saveNavState('', '/home')
      } else {
        const path = to.path
        this.setRoute(path)
      }
    },
  },
  methods: {
    ...mapActions('user', ['Logout']),
    //  获取所有的左侧菜单
    async getMenuList() {
      const menuListStr = sessionStorage.getItem('menuList')
      this.menuList = menuListStr ? JSON.parse(menuListStr) : []
    },

    // 重置路由
    setRoute(path) {
      const data = this.menuList
      for(let i=0; i<data.length; i++){
        const list = data[i].children
        for(let j=0; j<list.length; j++){
          const item = list[j]
          if(item.url == path) {
            this.saveNavState(item.id, path)
          }
        }
      }
    },

    //  保存链接的激活状态
    saveNavState(active, path) {
      this.active = active+""
      sessionStorage.setItem('menuSelectedKeys', active+"")
      this.$router.push(path)
      // this.$emit('close')
    },

    //  退出登录
    logout() {
      const that = this
      this.$confirm('确定要退出当前登录吗？', '退出', {
        confirmButtonText: '退出',
        cancelButtonText: '取消',
      }).then(()=>{
        that.Logout().then(()=>{})
        sessionStorage.clear()
        that.$router.replace('/login')
      }).catch(()=>{
        this.$message({type: 'warning', message: '取消操作', customClass: 'zindex-mess'})
      })
    },
  },
}
</script>

<style lang="less" scoped>
.index-container {
  height: 100%;
}

.el-header {
  height: 80px !important;
  background-image: url('../assets/image/header.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  font-size: 20px;
  z-index: 5000;
  img {
    width: 114px;
    height: 21px;
    margin-left: 30px; 
  }
  div {
    display: flex;
    align-items: center;
    font-size: 16px;
    padding-right: 20px;
    cursor: pointer;

    img {
      width: 15px;
      height: 15px;
      margin-right: 10px;
    }
  }
}

.cont {
  height: calc(100vh - 80px);
}

.el-aside {
  height: 100%;
}

::v-deep.el-menu {
  height: 100%;
  padding-top: 90px;
  box-sizing: border-box;
  background-image: url('../assets/image/asid.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;

  .el-submenu .el-submenu__title,
  .el-submenu__icon-arrow {
    font-size: 16px;
    color: #52596f;
  }
  .el-menu-item {
    font-size: 14px;
  }
  .el-submenu__title i {
    color: #52596f;
  }
}

</style>
