<template>
  <div class="login">
    <el-card class="box-card">
      <h2>欢迎登录</h2>
      <h2>临床案例评审系统</h2>
      <el-input  class="top" v-model="phone" placeholder="请输入手机号" @blur="testPhone"></el-input>
      <p class="hint">{{ phoneHint }}</p>
      <div  class="top">
        <el-input class="login-input" v-model="code" placeholder="请输入验证码"></el-input>
        <el-button class="have-code" type="primary" @click="getPhoneVerify" :disabled="isCount">
          {{isCount ? count+' s' : '获取验证码'}}
        </el-button>
      </div>
      <p class="hint">{{ codeHint }}</p>
      <el-button class="login-btn" type="primary" @click="login">登录</el-button>
    </el-card>
  </div>
</template>

<script>
import { sendSms } from '@/api/auth'
import md5 from 'js-md5'
import { mapActions } from 'vuex'

export default {
  name: 'Login',
  data () {
    return {
      phone: '',
      phoneHint: '',
      code: '',
      codeHint: '',
      timer: null,
      count: '',
      isCount: false
    }
  },
  methods: {
    ...mapActions('user', ['Login']),
    // 手机号验证
    testPhone () {
      const reg = /^1[3456789]\d{9}$/
      if (this.phone === '') {
        this.phoneHint = '请输入手机号'
      } else if (this.phone.length <= 10 || !reg.test(this.phone)) {
        this.phoneHint = '手机号格式错误'
      } else {
        this.phoneHint = ''
      }
    },

    //  获取验证码
    async getPhoneVerify() {
      if (!this.phone) {
        this.$message({type: 'error', message: '请输入手机号', customClass: 'zindex-mess'})
        return
      }
      if (this.phoneHint) {
        this.$message({type: 'error', message: this.phoneHint, customClass: 'zindex-mess'})
        return
      }
      const sig = md5(this.phone + 'yxczapp')
      const params = { phone: this.phone, sig }
      console.log('\n\n\n---params---', params)
      this.getCount()
      await sendSms(params)
    },

    // 倒计时
    getCount () {
      if (!this.timer) {
        this.count = 60
        this.isCount = true
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= 60) {
            this.count--
          } else {
            this.isCount = false
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    },

    //  登录
    async login() {
      if (!this.phone) {
        this.$message({type: 'error', message: '请输入手机号', customClass: 'zindex-mess'})
        return
      }
      if (this.phoneHint) {
        this.$message({type: 'error', message: this.phoneHint, customClass: 'zindex-mess'})
        return
      }
      if (!this.code) {
        this.$message({type: 'error', message: '请输入验证码', customClass: 'zindex-mess'})
        return
      }
      const that = this
      const params = { username: this.phone, code: this.code }
      this.Login(params)
        .then((res) => {
          that.loginSuccess(res)
        })
        .catch((res) => {
          that.$message({type: 'error', message: res.msg, customClass: 'zindex-mess'})
        })
    },

    // 登录成功
    loginSuccess(info) {
      const modules = info.data.modules || []
      const classifications = info.data.classifications || []
      const menuList = modules.length ? modules[0].menu : []
      const classifList = classifications.length ? classifications : []
      sessionStorage.setItem('menuList', JSON.stringify(menuList))
      sessionStorage.setItem('login', '1')
      sessionStorage.setItem('userId', info.data.userId)
      sessionStorage.setItem('userRole', info.data.role)
      sessionStorage.setItem('userClassId', info.data.classId)
      sessionStorage.setItem('userClassArrStr', JSON.stringify(classifList))
      sessionStorage.setItem('auditDate', info.data.auditTime)
      sessionStorage.setItem('editDate', info.data.updateTime)
      this.$nextTick(() => {
        this.$router.push('/home')
      })
    },
  },
}
</script>

<style lang="less" scoped>
.login {
  width: 100%;
  height: 100%;
  position: relative;
  background-image: url('../assets/image/login.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.box-card {
  width: 400px;
  padding: 40px 32px 55px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(0, -50%);
  border-radius: 12px;

  h2 {
    width: 100%;
    color: #409EFF;
    font-size: 30px;
    line-height: 46px;
  }

  ::v-deep.el-input__inner {
    font-size: 18px;
    height: 40px;
    line-height: 40px;
  }

  div {
    display: flex;
    align-items: center;
    .have-code {
      width: 130px;
      height: 40px;
      margin-left: 40px;
      font-size: 18px;
      line-height: 15px;
    }
  }

  .login-btn {
    margin-top: 40px;
    width: 100%;
    height: 50px;
    border-radius: 25px;
    font-size: 20px;
  }

  .top {
    margin-top: 10px;
  }

  .hint {
    width: 100%;
    height: 30px;
    line-height: 30px;
    padding-left: 15px;
    color: red;
    font-size: 14px;
  }
}
</style>
