import request from '@/utils/request'

// 发送验证码
export function sendSms (params) {
  return request.post('/admin/sendContribute', params)
}

// 手机号码登录
export function login (params) {
  const path = '/admin/login'
  const header = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
  return request.post(path, params, header)
}

// 退出登录
export function logout (params) {
  return request.post('/contribute-web/api/logout', params, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
}

// 获取系统时间  
export function getSysDate () {
  return request.get('/contribute-web/config/get')
}

// 设置系统时间
export function setSysDate (params) {
  return request.post('/contribute-web/config/save', params)
}

// 上传文件
export const upload = {
  action: 'https://contribute.hymdfoundation.org/contribute-web/file/upload', // 上传文件地址
};

