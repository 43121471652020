import Vue from 'vue'
import Router from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import routes from './routes'
import { ACCESS_TOKEN } from '@/store/mutation-types'

Vue.use(Router)
NProgress.configure({ showSpinner: false })

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

const whiteList = ['login', '404']

router.beforeEach((to, from, next) => {
  NProgress.start()
  const token = Vue.ss.get(ACCESS_TOKEN)
  if (token) {
    if (to.name === 'login') {
      next('/home')
    } else {
      next()
    }
  } else {
    if (whiteList.includes(to.name)) {
      next()
    } else {
      next({ path: '/login' })
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})

export default router
