import Vue from 'vue'
import store from '@/store/index'
import router from '@/router'
import axios from 'axios'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { Message } from 'element-ui'

// api 配置

let timer = null
let isLogin = true;

const onError = error => {
  if (error.response) {
    const status = error.response.status
    const message = error.response.statusText
    const token = Vue.ss.get(ACCESS_TOKEN)
    
    if (status === 403) {
      Message.error(message)
    }
    
    if (status === 404) {
      Message.error(message)
    }
    
    if (status === 500) {
      Message.error(message)
    }
    
    if (status === 401 && !timer) {
      timer = setTimeout(() => {
        Message.error('授权失败，请重新登录')
        if (token) {
          store.dispatch('user/Logout').then(() => router.replace('/login'))
        }
        timer = null
      }, 500)
    }
  }
  return Promise.reject(error)
}

const request = axios.create({
  baseURL: 'https://contribute.hymdfoundation.org',
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
  withCredentials: true,
  crossDomain: true,
  transformRequest: [
    function (data, headers) {
      // console.log(headers);
      if (headers['Content-Type'] === 'application/x-www-form-urlencoded') {
        return Object.keys(data).map(
          key => encodeURIComponent(key) + '=' +
            encodeURIComponent(
              /Object/i.test(data[key]) ? JSON.stringify(data[key]) : data[key]
            )
        ).join('&')
      } else {
        return JSON.stringify(data)
      }
    }
  ]
})

// 请求拦截器
request.interceptors.request.use(
  config => {
    // 开发环境下，如果请求是 post,put,patch,则打印数据体，方便调试
    if (process.env.NODE_ENV === 'development') {
      const { method } = config
      if (['post', 'put', 'patch'].includes(method)) {
        // console.log(config.data)
      }
    }
    
    return config
  },
  error => {
    Message.error('发送请求失败，请检查您的网络')
    return Promise.reject(error)
  }
)

// 响应拦截器
request.interceptors.response.use(res => {
  const jsonPattern = /application\/json/gi
  const white = res.config.url.includes('/tag-core');

  if (jsonPattern.test(res.headers['content-type'])) {
    const data = res.data || {};
    if (data.code === 600 && isLogin && !white) {
      Message.error('登录失效，请重新登录')
      isLogin = false;
      window.top.location.href = 'https://medline.hymdfoundation.org/#/login';
      return false;
    }
    return res.data;
  } else {
    if (res.code === 600 && isLogin && !white) {
      Message.error('登录失效，请重新登录')
      isLogin = false;
      window.top.location.href = 'https://medline.hymdfoundation.org/#/login';
      return false;
    }
    return res;
  }
}, onError)

export default request
