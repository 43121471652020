<template>
  <div class="audit">
    <!-- 筛选 -->
    <div class="header-search">
      <el-input v-model="name" placeholder="名称模糊搜索"></el-input>
      <el-select v-model="isDelete" placeholder="请选择状态">
        <el-option
          v-for="item in deleteOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-button type="primary" icon="el-icon-search" @click="search">筛选</el-button>
      <el-button type="danger" icon="el-icon-brush" @click="refresh">清除</el-button>
      <el-button
      class="add-btn"
      type="primary"
      icon="el-icon-plus" 
      v-if="userRole !== 3" 
      @click="AuditAdd(0)">
        新增初审专家
      </el-button>
    </div>
    <!-- 有数据 -->
    <template v-if="tableData.length">
      <!-- 列表 -->
      <div class="content-table">
        <el-table :data="tableData" border stripe style="width: 100%">
          <el-table-column label="序号" align="center" width="100">
            <template slot-scope="scope">{{ getNumId(scope.$index) }}</template>
          </el-table-column>
          <el-table-column prop="name" label="名称" align="center" />
          <el-table-column prop="hosName" label="执业机构" width="250" />
          <el-table-column prop="depart" label="科室" align="center" />
          <el-table-column prop="domain" label="擅长病种" align="center" />
          <el-table-column prop="pendingNum" label="在审" align="center" />
          <el-table-column prop="createTime" label="入驻时间" align="center" />
          <el-table-column label="状态" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.isDelete ? '不可用' :'可用'}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="150" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="openDetail(scope.row.id)">详情</el-button>
              <el-button type="text" @click="AuditAdd(1, scope.row.id)">编辑</el-button>
              <el-button type="text" @click="openModal(scope.row)">状态</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="footer-page">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="pageChange"
          :total="total"
          :page-size="10"
          :current-page="page">
        </el-pagination>
      </div>
    </template>
    <!-- 无数据 -->
    <template v-else>
      <div class="empty"><el-empty :image-size="200"></el-empty></div>
    </template>
    <!-- 状态弹窗 -->
    <state-modal
    :visible="visible" :info="editInfo" :page="page"
    @close="closeModal" @getData="getUserList" />
  </div>
</template>

<script>
import StateModal from '../../components/user/StateModal.vue'
import { getUserList } from '@/api/user'

export default {
  name: 'Audit',
  components: { StateModal },
  data() {
    return {
      // 基础数据
      userRole: '', // 1: 超级管理员、2：领域管理员、3：责任编辑、4：初审专家
      name: '',
      isDelete: '',
      deleteOptions: [{
        value: '0',
        label: '可用'
      }, {
        value: '1',
        label: '不可用'
      }],
      tableData: [],
      total: 0,
      page: 1,
      // 编辑状态
      visible: false,
      editInfo: {}
    }
  },
  created () {
    this.userRole = sessionStorage.getItem('userRole')
    this.getUserList(1)
  },
  methods: {
    // 获取初审专家列表
    async getUserList(page) {
      const params = {
        role: 4,
        pageNum: page,
        pageSize: 10,
      }
      if(this.name) { params.name = this.name }
      if(this.isDelete) { params.isDelete = this.isDelete }
      console.log('\n\n---params---', params)
      const res = await getUserList(params)
      if(res.code === 0) {
        const resData = res.data || {}
        this.tableData = resData.rows || []
        this.total = resData.total || 0
        this.page = page
      }
    },
    // 筛选
    search() {
      this.getUserList(1)
    },
    // 清除筛选
    refresh() {
      this.name = ""
      this.isDelete = ""
      this.getUserList(1)
    },
    // 获取序号
    getNumId(index) {
      const num1 = index + 1
      const num2 = (this.page - 1) * 10
      return Number(num1 + num2)
    },
    // 分页
    pageChange(page) {
      this.getUserList(page)
    },
    // 打开 状态编辑弹窗
    openModal(info) {
      this.visible = true
      this.editInfo = info
    },
    // 关闭 状态编辑弹窗
    closeModal() {
      this.visible = false
      this.editInfo = {}
    },
    // 新建、修改 审核专家
    AuditAdd(type, id) {
      this.$router.push({
        path: '/audit-add',
        query: { userId: id, type }
      });
    },
    // 查看详情
    openDetail(id) {
      this.$router.push({
        path: '/audit-detail',
        query: { userId: id }
      });
    }
  }
}
</script>

<style lang="less" scoped>
.audit {
  width: 100%;
  height: 100%;
}

.empty {
  width: 100%;
	margin-top: 32px;
  background-color: #fff;
}
</style>
