<template>
  <div class="user-info">
		<header-title :title="title" :border="true" />
		<ul v-if="checkUserRole == 4">
			<li>
				<p>姓名：{{info.name}}</p>
				<p>科室：{{info.depart}}</p>
				<p>学科：{{info.className}}</p>
			</li>
			<li>
				<p>职业机构：{{info.hosName}}</p>
				<p>行政职务：{{info.duty}}</p>
				<p>擅长疾病：{{info.domain}}</p>
			</li>
			<li>
				<p>临床职称：{{info.profName}}</p>
				<p>学术职称：{{info.academicName}}</p>
				<p>手机号码：{{info.phone}}</p>
			</li>
		</ul>
		<ul v-else class="info">
			<li>
				<p>姓名：{{info.name}}</p>
				<p>单位：{{info.hosName}}</p>
			</li>
			<li>
				<p>手机号码：{{info.phone}}</p>
				<p>关联领域：{{info.className}}</p>
			</li>
			<li></li>
		</ul>
  </div>
</template>

<script>
import HeaderTitle from '../HeaderTitle.vue'

export default {
  name: 'UserInfo',
	components: { HeaderTitle },
  props: {
    title: {
      type: String,
      default: ''
    },
	checkUserRole: {
      type: Number | String,
      default: ''
    },
    info: Object
  },
  data () {
    return {}
  },
}
</script>

<style lang="less" scoped>
.user-info {
	width: 100%;
	margin-top: 32px;
	box-sizing: border-box;
	background-color: #fff;
	font-size: 18px;

	h2 {
		font-size: 18px;
	}

	ul {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 32px 52px 40px;
		box-sizing: border-box;
	}

	.info {
		li {
			&:last-child {
				opacity: 0;
			}
		}
	}

	li {
		width: 25%;
		padding: 32px;
		background-color: #F6F9FF;
		border-radius: 12px;
		p {
			line-height: 50px;
		}
	}
}
</style>
