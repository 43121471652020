<template>
  <div class="state-modal">
    <el-dialog
    :visible="visible"
    width="600px"
    @close="closeModal(false)"
    :footer="null">
      <h2 class="title">状态设置</h2>
      <div class="state">
        <el-radio v-model="radio" border label="0">可用</el-radio>
        <el-radio v-model="radio" border label="1">不可用</el-radio>
      </div>
      <p class="tips">备注：设为不可用，该账号将无法登录系统，请谨慎操作。</p>
      <el-button class="btn" type="primary" @click="confirm">确定</el-button>
    </el-dialog>
  </div>
</template>

<script>
import { editState } from '@/api/user'

export default {
  name: 'StateModal',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    page: {
      type: Number,
      default: 1
    },
    info: Object
  },
  data () {
    return {
			userId: '',
      radio: ''
    }
  },
  watch: {
    info: {
      handler(newInfo, oldInfo){
        this.radio = newInfo.isDelete+'',
        this.userId = newInfo.id
      },
      deep: true,
      immediate: true
    }
  },
  computed: {},
  created () {},
  methods: {
    closeModal() {
      this.$emit('close');
    },
    async confirm() {
      const params = {
        isDelete: Number(this.radio),
        id: this.userId
      }
      const res = await editState(params)
      if(res.code === 0) {
        this.$message({type: 'success', message: '修改成功', customClass: 'zindex-mess'})
         this.$emit('getData', this.page);
      }
      this.closeModal()
    }
  }
}
</script>

<style lang="less" scoped>
.state-modal {
  text-align: center;

  .title {
    width: 100%;
    margin-bottom: 50px;
  }

  .state {
    margin-bottom: 20px;

    .el-radio {
      width: 120px;
      margin-left: 30px;
      margin-right: 30px;
    }
  }

  .tips {
    margin-bottom: 60px;
    font-size: 16px;
  }

  .btn {
    width: 150px;
    margin-bottom: 30px;
  }
}
</style>
