import request from '@/utils/request'

// 获取用户列表
export function getUserList (params) {
  return request.post('/contribute-web/user/showList', params)
}

// 新建用户
export function addUser (params) {
	return request.post('/contribute-web/user/addUser', params)
}

// 编辑用户
export function updateUser (params) {
	return request.post('/contribute-web/user/updateUser', params)
}

// 修改用户状态
export function editState (params) {
	const { isDelete, id } = params
	return request.get(`/contribute-web/user/editState?isDelete=${isDelete}&id=${id}`)
}

// 获取用户详情
export function getUserDetail (params) {
	const { id } = params
	return request.get('/contribute-web/user/get?id='+id)
}

// 根据分类获取用户列表
export function getUserData (params) {
	const { classId, role } = params
	return request.get(`/contribute-web/user/getRole?classId=${classId}&role=${role}`)
}

// 获取数据字典 
export function getDict (params) {
	const { type, pid } = params
	return request.get(`/contribute-web/dict/getDict?type=${type}&pid=${pid}`)
}

// 获取关联领域
export function getClassification () {
	return request.get('/contribute-web/user/getClassification')
}