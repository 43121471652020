<template>
	<div class="personal">
		<header-title title="个人信息" />

		<div class="content">
			<el-form
			v-if="userRole == 4"
			:model="ruleForm" 
			:rules="rules" 
			ref="ruleForm" 
			label-width="200px" 
			class="demo-ruleForm">
				<el-form-item label="姓名：" prop="name">
					<el-input v-model="ruleForm.name" placeholder="请输入姓名"></el-input>
				</el-form-item>
				<el-form-item label="科室：" prop="depart">
					<el-input v-model="ruleForm.depart" placeholder="请输入科室"></el-input>
				</el-form-item>
				<el-form-item label="执业机构：" prop="hosName">
					<el-input v-model="ruleForm.hosName" placeholder="请输入执业机构"></el-input>
				</el-form-item>
				<el-form-item label="学术职称：" prop="academic">
					<el-select v-model="ruleForm.academic" placeholder="请选择学术职称">
						<el-option 
						v-for="(item,index) in acadOption" 
						:key="index"
						:label="item.name"
						:value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="临床职称：" prop="prof">
					<el-select v-model="ruleForm.prof" placeholder="请选择临床职称">
						<el-option 
						v-for="(item,index) in profOption" 
						:key="index"
						:label="item.name"
						:value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="行政职务：" prop="duty">
					<el-input v-model="ruleForm.duty" placeholder="请输入行政职务"></el-input>
				</el-form-item>
				<el-form-item label="擅长疾病：" prop="domain">
					<el-input v-model="ruleForm.domain" placeholder="请输入擅长疾病"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button class="submit" type="primary" @click="submitForm(0)">确定</el-button>
				</el-form-item>
			</el-form>
			<el-form
			v-else
			:model="ruleForm" 
			:rules="rules" 
			ref="ruleForm" 
			label-width="200px" 
			class="demo-ruleForm">
				<el-form-item label="姓名：" prop="name">
					<el-input v-model="ruleForm.name" placeholder="请输入姓名"></el-input>
				</el-form-item>
				<el-form-item label="单位简称：" prop="hosName">
					<el-input v-model="ruleForm.hosName" placeholder="请输入单位简称"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button class="submit" type="primary" @click="submitForm(1)">确定</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
import HeaderTitle from '../../components/HeaderTitle.vue'
import { getUserDetail, updateUser, getDict } from '@/api/user'

export default {
	name: 'Personal',
	components: { HeaderTitle },
	data() {
		return {
			userRole: '', // 1: 超级管理员、2：领域管理员、3：责任编辑、4：初审专家
			userId: '',
			profOption: [],
			acadOption: [],
			ruleForm: {},
			rules: {
				name: this.getRegu('姓名'),
				hosName: this.getRegu(this.userRole == 4 ? '执业机构' : '单位简称'),
				depart: this.getRegu('科室'),
				academic: [
					{ required: true, message: '请选择学术职称', trigger: 'change' }
				],
				prof: [
					{ required: true, message: '请选择临床职称', trigger: 'change' }
				],
				duty: this.getRegu('行政职务'),
				domain: this.getRegu('擅长疾病'),
			},
		}
	},
	created () {
		this.userRole = sessionStorage.getItem('userRole')
		this.userId = sessionStorage.getItem('userId')
		this.getDict()
		this.getAcadDict()
		this.getInfo(this.userId)
	},
	methods: {
		// 正则校验
		getRegu(name) {
			return [
				{ required: true, pattern: /[^ \x22]+/, message: '请输入'+name, trigger: 'blur' },
				{ max: 200, message: '最多输入200个字符', trigger: 'blur' }
			]
		},

		// 获取 审核专家信息
		async getInfo(id) {
			const res = await getUserDetail({id})
			if(res.code === 0) {
				this.ruleForm = res.data
			}
		},

		// 获取学术职称
		async getAcadDict() {
			const params = { pid: '', type: 30 }
			const res = await getDict(params)
			if(res.code === 0) {
				this.acadOption = res.data
			}
		},

		// 获取临床职称
		async getDict() {
			const params = { pid: '', type: 5 }
			const res = await getDict(params)
			if(res.code === 0) {
				this.profOption = res.data
			}
		},

		// 提交
		submitForm(type) {
			const params = {...{id: this.userId}, ...this.ruleForm}
			console.log('\n\n\n---type---', type)
			console.log('\n\n\n---params---', params)
			this.$refs['ruleForm'].validate(async (valid) => {
				if(!valid) { return false; }
				const res = await updateUser(params)
				if(res.code === 0) {
					this.$message({type: 'success', message: '修改成功', customClass: 'zindex-mess'})
				}
			});
		},
	}
}
</script>

<style lang="less" scoped>
.personal {
  width: 100%;
  height: 100%;
}

.content {
	width: 100%;
	margin-top: 32px;
	padding: 50px 0 20px;
	background-color: #fff;
}

::v-deep.el-form {
	width: 800px;
	margin: 0 auto;
	.el-form-item {
		.el-form-item__label, p {
			font-size: 16px;
		}

		.el-select {
			width: 100%;
		}

		.level {
			width: 80%;
		}
		.add-btn {
			width: 80px;
			float: right;
		}

		.el-tag {
			height: 36px;
			line-height: 36px;
			font-size: 16px;
			margin-right: 10px;
		}

		.submit {
			width: 120px;
			margin: 50px 0 0 180px;
		}
	}
}
</style>
