<template>
  <div class="check-detail">
    <header-title :title="'申请单编号-'+info.uid" />
    <div class="back">
      <el-button type="primary" @click="callBack">返回</el-button>
    </div>
    <!-- 投稿信息 -->
    <div class="box">
      <header-title title="投稿信息" :border="true" />
      <div class="list" v-if="userRole+'' === '4'">
        <el-row>
          <el-col :span="8">
            <span class="name">申请单编号：</span><span>{{ info.uid }}</span>
          </el-col>
          <el-col :span="8">
            <span class="name">责任编辑：</span><span>{{ info.editorName }}</span>
          </el-col>
          <el-col :span="8">
            <span class="name">投稿时间：</span><span>{{ info.createTime }}</span>
          </el-col>
        </el-row>
      </div>
      <div class="list" v-else>
        <el-row>
          <el-col :span="8">
            <span class="name">申请单编号：</span><span>{{ info.uid }}</span>
          </el-col>
          <el-col :span="8">
            <span class="name">投稿医生：</span><span>{{ info.creatorName }}</span>
          </el-col>
          <el-col :span="8">
            <span class="name">投稿时间：</span><span>{{ info.createTime }}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <span class="name">执业机构：</span><span>{{ creatorInfo.hosName }}</span>
          </el-col>
          <el-col :span="8">
            <span class="name">执业类别：</span><span>{{ creatorInfo.prctcRange }}</span>
          </el-col>
          <el-col :span="8">
            <span class="name">科室：</span><span>{{ creatorInfo.depart }}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <span class="name">执业范围：</span><span>{{ creatorInfo.prctcRangeChild }}</span>
          </el-col>
          <el-col :span="8">
            <span class="name">执业证号：</span><span>{{ creatorInfo.prctcCertificateNumber }}</span>
          </el-col>
          <el-col :span="8">
            <span class="name">职称：</span><span>{{ creatorInfo.prof }}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <span class="name">执业年限：</span><span>{{ creatorInfo.num }}</span>
          </el-col>
          <el-col :span="8">
            <span class="name">投稿来源：</span><span>{{ creatorInfo.source }}</span>
          </el-col>
          <el-col :span="8">
            <span class="name">责任编辑：</span><span>{{ info.editorName }}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <span class="name">手机号码：</span><span>{{ creatorInfo.phone }}</span>
          </el-col>
          <el-col :span="8">
            <span class="name">电子邮箱：</span><span>{{ creatorInfo.doctorEmail }}</span>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 投稿基本信息 -->
    <div class="box">
      <header-title title="投稿基本信息" :border="true" />
      <div class="basic">
        <h2 class="title">{{ info.title }}</h2>
        <div class="author" v-if="userRole+'' !== '4'">
          <p v-for="(item, index) in info.authors" :key="index">
            <span>{{ item.name }}</span>
            <span>（{{ item.hosName }},{{ item.address }},{{ item.postcode }}）</span>
          </p>
        </div>
        <h2 class="digest">摘要</h2>
        <div class="text">
          <template v-if="lengthState">
            <div v-if="!isShow">
              <div>{{ info.summary.slice(0, 230) }} ...</div>
              <div class="text-btn" @click="isShowClick(true)">展开</div>
            </div>
            <div v-else>
              <div>{{ info.summary }}</div>
              <div class="text-btn" @click="isShowClick(false)">收起</div>
            </div>
          </template>
          <template v-else>
            <div>{{ info.summary }}</div>
          </template>
        </div>
      </div>
    </div>
    <!-- 文档上传记录 -->
    <div class="box">
      <header-title title="文档记录" :border="true" />
      <div class="upload-box" v-if="userRole == 3 && state < 11">
        <el-button type="primary" icon="el-icon-upload" @click="uploadFile(0)"
        :loading="loading && !fileId" :disabled="loading">
          上传文件
        </el-button>
      </div>
      <div class="record">
        <el-table :data="flieData" border stripe style="width: 100%">
          <el-table-column label="序号" align="center">
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column label="文件" width="300">
            <template slot-scope="scope">
              {{ scope.row.fileName ? (scope.row.type == 2 ? '【双盲稿件】'+scope.row.fileName : scope.row.fileName) : '' }}
            </template>
          </el-table-column>
          <el-table-column label="上传人" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.creatorName}}</span>
              <span>（{{scope.row.role==5?'投稿医师':(scope.row.role==4?'初审专家':'责任编辑')}}）</span>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="上传时间" align="center"/>
          <el-table-column prop="updateTime" label="修改时间" align="center"/>
          <el-table-column label="操作" width="150" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="downloadFile(scope.row.file)">下载</el-button>
              <el-button type="text"
              @click="deleteFile(scope.row.id)"
              v-if="scope.row.type == 3 && userId == scope.row.userId && state < 11">
                删除
              </el-button>
              <el-button type="text"
              :loading="loading && scope.row.id === fileId"
              :disabled="loading"
              v-if="scope.row.type < 3 && userRole == 3 && state < 11"
              @click="uploadFile(scope.row.id, scope.row.type)">
                替换
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 审批操作 -->
    <div class="box" v-if="isShowCheck">
      <header-title title="审批操作" :border="true" />
      <div class="action" v-if="userRole == 3">
        <div class="action-div">
          <p>1、格式审核：</p>
          <el-radio-group v-model="layout">
            <el-radio :label="1">通过</el-radio>
            <el-radio :label="2">不通过</el-radio>
          </el-radio-group>
        </div>
        <div class="action-div action-select">
          <p>2、指派初审专家：</p>
          <el-select v-model="audit" placeholder="请选择初审专家" size="small" 	@change="selectChange($event)">
            <el-option
              v-for="item in auditData"
              :key="item.id"
              :label="item.name"
              :value="item.id + '&' + item.name + '&' + item.pendingNum">
            </el-option>
          </el-select>
          <el-button type="primary" size="small" @click="addAudit">添加</el-button>
          <el-tag
						v-for="(tag, index) in selectAudits"
						:key="index"
						effect="dark"
						@close="deletelevel(index)"
						closable>
            {{`${tag.name}(在审 ${tag.num})`}}
					</el-tag>
        </div>
        <div class="action-div radio-div">
          <p>3、稿件处理：</p>
          <el-radio v-model="auditResult" label="5">打回投稿医师修改</el-radio>
          <el-radio v-model="auditResult" v-for="(item, index) in selectAudits" :key="index" :label="10+'&'+index">
            {{`指派${item.name}(初审专家)`}}
          </el-radio>
          <el-radio v-model="auditResult" v-if="selectAudits.length > 1" label="10">同时指派给所有初审专家</el-radio>
          <el-radio v-model="auditResult" label="11">初审通过</el-radio>
          <el-radio v-model="auditResult" label="15">退稿</el-radio>
        </div>
        <div class="action-input">
          <el-input
            type="textarea"
            :rows="4"
            placeholder="请输入审核意见"
            maxlength="500"
            show-word-limit
            v-model="inputValue">
          </el-input>
        </div>
        <div class="btn">
          <el-button
          :disabled="state < 11 ? false : true"
          type="primary"
          :loading="assiLoading"
          @click="assign()">
            提交
          </el-button>
        </div>
      </div>
      <div class="action" v-else>
        <div class="action-div">
          <p>1、下载病历源文件：</p>
          <el-button type="text" @click="downloadFile(info.dbDraft)">点击下载</el-button>
        </div>
        <div class="action-div">
          <p>2、全文批注：在word或者 WPS 中打开审阅-修订模式，进行批注和审阅。保存后上传。</p>
          <el-button type="primary" icon="el-icon-upload"
            @click="uploadFile(0)" :loading="loading" size="small" :disabled="loading">
            上传文件
          </el-button>
        </div>
        <div class="action-div">
          <p>3、审核意见：</p>
          <el-radio-group v-model="ideaResult">
            <el-radio :label="1">通过</el-radio>
            <el-radio :label="2">不通过</el-radio>
            <el-radio :label="3">退回修改</el-radio>
          </el-radio-group>
        </div>
        <div class="action-input">
          <el-input
            type="textarea"
            :rows="4"
            placeholder="请输入审核意见"
            maxlength="500"
            show-word-limit
            v-model="inputValue">
          </el-input>
        </div>
        <div class="btn" v-if="state < 11">
          <el-button type="primary" :loading="editLoading" @click="editState()">提交</el-button>
        </div>
      </div>
    </div>
    <!-- 临床案例评分标准  -->
    <div class="box" v-if="userRole == 1 || userRole == 2 || userRole == 3 || (userRole == 4 && ideaResult === 1)">
      <header-title :title="scoreTitle" :border="true" />
      <div class="action">
      <table class="exam-result"  cellspacing="0" border="1">
        <thead class="simple-table-head">
          <tr class="active">
            <th>评价总因素</th>
            <th>评价子因素</th>
            <th>满分</th>
            <th v-if="userRole == 4">实际得分</th>
            <template v-if="userRole != 4 && scoreList.length">
              <th v-for="(score, scoreIndex) in scoreList" :key="scoreIndex">{{ score.name }}</th>
            </template>
          </tr>
        </thead>
        <tbody>
          <template  v-for="(inter, index) in scoreTableList" >
            <tr v-if="inter.key !== 'totalPoints'" :class="inter.className && inter.className" :key="index">
              <td v-if="inter.title" :rowspan="inter.rowspan" :class="['default-td', inter.title === '实用性（25分）' ? 'active' : '']">
                {{ inter.title }}
              </td>
              <td>{{ inter.subfactor }}</td>
              <td class="weight-td">{{ inter.fullScore }}</td>
              <td v-if="userRole == 4">
                <el-input-number
                  v-model="inter.score"
                  controls-position="right"
                  :min="0"
                  :max="inter.fullScore"
                ></el-input-number>
              </td>
              <template v-if="inter.list">
                <td class="weight-td" v-for="(score, scoreIndex) in inter.list" :key="scoreIndex">{{ score }}</td>
              </template>
            </tr>
            <!-- 合计 -->
            <tr v-else-if="scoreList.length" :key="index" class="active">
              <td :colspan="inter.colspan">{{ inter.subfactor }}</td>
              <template v-if="inter.list">
                <td class="tag-td" v-for="(score, scoreIndex) in inter.list" :key="scoreIndex">{{ score }}</td>
              </template>
            </tr>
          </template>
          <!-- 平均分 -->
          <template v-if="userRole != 4 && scoreList.length">
            <tr>
              <td colspan="3">
                平均分
              </td>
              <td class="tag-td" :colspan="scoreList.length">
                {{ this.info.average || 0 }} <span>{{ this.info.isExcellent === 1 ? '（优秀稿件）' : '' }}</span>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      </div>
    </div>
    <!-- 审批日志 -->
    <div class="box">
      <header-title title="审批日志" :border="true" />
      <p class="code">申请编号：{{ info.uid }}</p>
      <div class="log" v-if="checkDate.length">
        <div class="log-item" v-for="(item, index) in checkDate" :key="index">
          <div class="image">
            <img v-if="item.state < 8" alt="" src="../../assets/image/finish.png" />
            <img v-else alt="" src="../../assets/image/wait.png" />
            <p v-if="index" :id="'line'+index" :class="item.state ? 'line' : 'line wait'"></p>
          </div>
          <ul class="steps">
            <li class="first">
              <p><span class="title">{{roleObj[item.role]}}： </span><span>{{ item.userName }}</span></p>
              <p><span class="title">{{item.role === 5 ? '提交时间：' : '审批时间：'}} </span><span>{{ item.createTime }}</span></p>
            </li>
            <li>
              <p><span class="title">{{item.role === 5 ? '投稿说明：' : '审批说明：'}} </span><span>{{ item.desc }}</span></p>
            </li>
            <img class="result" alt="" :src="stateImg[item.state]" />
          </ul>
        </div>
      </div>
      <div class="log" v-else>
        <el-empty :image-size="200"></el-empty>
      </div>
    </div>

    <el-upload
      ref="upload"
      :http-request="upload"
      :show-file-list="false"
      multiple
      action
      style="display: none"
    >
    </el-upload>
  </div>
</template>

<script>
import { Message } from 'element-ui'
import reqwest from "reqwest";
import HeaderTitle from '../../components/HeaderTitle.vue'
import { getContent, getContentFile, getAuditLog, editState,
assign, addContentFile, updContentFile, delContentFile, getScoreList } from '@/api/case'
import { scoreTableList } from '@/utils/clinical-score'
import { getUserData } from '@/api/user'
import { upload } from '@/api/auth'
import editImg from "../../assets/image/edit.png"
import assignImg from "../../assets/image/assign.png"
import passImg from "../../assets/image/pass.png"
import rejectImg from "../../assets/image/reject.png"
import backImg from "../../assets/image/back.png"
import firstPassImg from "../../assets/image/first-pass.png"
import lastPassImg from "../../assets/image/last-pass.png"
import lastRejectImg from "../../assets/image/last-reject.png"
import contributeImg from "../../assets/image/contribute.png"
import editContributeImg from "../../assets/image/edit-contribute.png"
import doctorEditImg from "../../assets/image/doctor-edit.png"

export default {
  name: 'CheckDetail',
  components: { HeaderTitle },
  data() {
    return {
      userRole: '',
      userClassId: '',
      userId: '',
      contentId: '',
      isShowCheck: false,
      info: {},
      creatorInfo: {},
      flieData: [],
      checkDate: [],
      result: '',
      content: '',
      state: '',
      roleObj: {
        '1': '超级管理员',
        '2': '领域管理员',
        '3': '责任编辑',
        '4': '初审专家',
        '5': '投稿医师',
      },
      // 0-初审专家通过 1-初审专家不通过 2-打回医师修改 3-指派初审专家 4-初审通过 
      // 5-退稿 6-终审通过 7-终审不通过 8-投稿 9-修改稿件 10-退回责任编辑修改
      stateImg: {
        '0': passImg,
        '1': rejectImg,
        '2': doctorEditImg,
        '3': assignImg,
        '4': firstPassImg,
        '5': backImg,
        '6': lastPassImg,
        '7': lastRejectImg,
        '8': contributeImg,
        '9': editContributeImg,
        '10': editImg,
      },
      loading: false,
      fileId: '',
      fileType: '',
      // 展开收起
      isShow: false,
      lengthState: false,
      // 操作
      layout: '',
      audit: '',
      auditData: [],
      selectAudits: [],
      auditId: '',
      auditName: '',
      auditNum: '',
      auditResult: '',
      inputValue: '',
      ideaResult: '',
      assiLoading: false,
      editLoading: false,
      scoreTableList: JSON.parse(JSON.stringify(scoreTableList)),
      scoreList: []
    }
  },
  computed: {
    scoreTitle () {
      const auditInfo = this.auditData.find(audit => audit.id == this.userId) || { name: '' }
      return +this.userRole === 4 ? `临床案例评分标准（评分人：${auditInfo.name}）` : '临床案例评分标准';
    }
  },
  async created () {
    this.userRole = sessionStorage.getItem('userRole')
    this.userClassId = sessionStorage.getItem('userClassId')
    this.userId = sessionStorage.getItem('userId')
    const query = this.$route.query;
    this.contentId = query.id
    this.isShowCheck = query.isShowCheck
    this.getAuditData()
    this.getInfo(query.id)
    this.getFileData(query.id)
    await this.getCheckData(query.id)
    await this.setStape()
    await this.getScoreList(query.id)
  },
  methods: {
    // 获取评分列表
    async getScoreList(contentId) {
      const res = await getScoreList({
        contentId
      })
      if(res.code === 0) {
        const scoreInfo = res.data.find(item => item.userId == this.userId)
        if (this.userRole != 4) {
          // 回显初审者的评分
          this.scoreList = res.data;
          this.scoreTableList.forEach(item => {
            item['list'] = res.data.map(list => list[item.key]);
          })
        } else if (this.userRole == 4 && scoreInfo) {
          // 回显当前初审者历史打分
          this.scoreTableList.forEach(item => {
            item.score = scoreInfo[item.key];
          })
        }
      }
    },
    // 获取稿件基本信息
    async getInfo(id) {
      const res = await getContent({id})
      if(res.code === 0) {
        const resData = res.data || {}
        this.info = resData
        this.creatorInfo = resData.info || {}
        this.state = resData.state || ''
        this.layout = resData.formatState || ''
        const content = resData.summary || ''
        // console.log('\n\n\n---content.length---', content.length)
        if(resData.expertAuditState !== null) {
          this.ideaResult = resData.expertAuditState === 1 ? 2 : (resData.expertAuditState === 10 ? 3 : 1)
        }
        this.inputValue = resData.expertAuditDesc || ''
        if(content.length > 249) {
          this.lengthState = true
        }
      }
    },
    // 获取文件列表
    async getFileData(id) {
      const params = { contentId: id }
      const res = await getContentFile(params)
      if(res.code === 0) {
        this.flieData = res.data
      }
    },
    // 获取稿件审批日志
    async getCheckData(id) {
      const params = { contentId: id }
      const res = await getAuditLog(params)
      if(res.code === 0) {
        this.checkDate = res.data
      }
    },
    // 展开收起
    isShowClick(state) {
      this.isShow = state
    },
    // 设置步骤条
    setStape() {
      const listStep = document.getElementsByClassName("steps")
      for(let i=1; i<listStep.length; i++) {
        const offsetHeight = listStep[i].offsetHeight + listStep[i-1].offsetHeight
        const height = offsetHeight + 13
        const line = document.getElementById('line'+i)
        if(line) {
          line.style.height = (height * 0.38)+'px'
        }
      }
    },
    // 获取初审专家列表
    async getAuditData() {
       const params = {
        classId: this.userClassId,
        role: 4
      }
      const res = await getUserData(params)
      if(res.code === 0) {
        this.auditData = res.data
      }
    },
    // 添加/替换 文件
    uploadFile(id, type) {
      this.fileId = id
      if(type) { this.fileType = type }
      this.$refs["upload"].$children[0].$refs.input.click();
    },
    upload(params) {
      this.loading = true
      const that = this
      const file = params.file
      const fileType = 'doc,docx'
      const type = file.name.substr(file.name.lastIndexOf('.') + 1).toLocaleLowerCase();
      const verifyList = [
        {
          text: "格式错误，请上传doc，docx格式文件",
          result: fileType.includes(type),
        }
      ];
      for (let item of verifyList) {
        if (!item.result) {
          Message({type: 'error', message: item.text, customClass: 'zindex-mess'})
          this.loading = false
          return;
        }
      }
      const form = new FormData();
      form.append("file", file);
      form.append("clientType", "multipart/form-data");
      reqwest({
        url: upload.action,
        method: "post",
        processData: false,
        withCredentials: true,
        data: form,
        success: (res) => {
          if (res.code !== 0) {
            Message({type: 'error', message: res.msg ? res.msg : "请重新上传", customClass: 'zindex-mess'})
            this.loading = false
            return;
          }
          if(that.fileId) {
            that.updContentFile(res.data, that.fileId)
          } else {
            that.addContentFile(res.data)
          }
        },
      });
    },
    // 添加文件
    async addContentFile(info) {
      const params = {
        fileName: info.fileName,
        file: info.url,
        contentId: this.contentId,
        type: 3
      }
      const res = await addContentFile(params)
      if(res.code === 0) {
        Message({type: 'success', message: "添加成功", customClass: 'zindex-mess'})
        this.getFileData(this.contentId)
      } else {
        Message({type: 'error', message: res.msg ? res.msg : "添加失败", customClass: 'zindex-mess'})
      }
      this.loading = false
    },
    // 替换文件
    async updContentFile(info, id) {
       const params = {
        fileName: info.fileName,
        file: info.url,
        id,
        type: this.fileType
      }
      const res = await updContentFile(params)
      if(res.code === 0) {
        Message({type: 'success', message: "替换成功", customClass: 'zindex-mess'})
        this.getFileData(this.contentId)
      } else {
        Message({type: 'error', message: res.msg ? res.msg : "替换失败", customClass: 'zindex-mess'})
      }
      this.loading = false
    },
    // 删除文件
    deleteFile(id) {
      const that = this
      this.$confirm('确定要删除改文件吗？', '删除', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
      }).then(async ()=>{
        const res = await delContentFile({id})
        if(res.code === 0) {
          Message({type: 'success', message: "删除成功", customClass: 'zindex-mess'})
          that.getFileData(that.contentId)
        }
      }).catch(()=>{
        Message({type: 'warning', message: "取消操作", customClass: 'zindex-mess'})
      })
    },
    // 选择初审专家
		selectChange(value) {
			const valueList = value.split("&")
      this.auditId = valueList[0]
      this.auditName = valueList[1]
      this.auditNum = valueList[2]
		},
    // 添加初审专家
    addAudit() {
      const value = this.audit
			const params = { id: this.auditId, name: this.auditName, num: this.auditNum }
			if(!value){
        Message({type: 'error', message: "请选择初审专家", customClass: 'zindex-mess'})
        return false
      }
			const index = this.deleteRepete(this.selectAudits, "id", params.id);
			 if (index < 0) {
        this.selectAudits.push(params);
      } else {
        Message({type: 'error', message: "该初审专家已添加", customClass: 'zindex-mess'})
      }
    },
    // 数组去重
		deleteRepete(list, key, value) {
			for (let i = 0; i < list.length; i++) {
        if (list[i][key] === value) { return i; }
      }
      return -1;
		},
		// 删除初审专家
		deletelevel(index) {
			this.selectAudits.splice(index, 1)
		},
    // 责任编辑提交
    async assign() {
      this.assiLoading = true
      const params = { id: this.contentId }
      if(!this.layout) {
        Message({type: 'error', message: "请选择格式审核", customClass: 'zindex-mess'})
        this.assiLoading = false
        return false
      }
      params['formatState'] = this.layout > 1 ? 0 : 1
      if(!this.auditResult) {
        Message({type: 'error', message: "请选择稿件处理", customClass: 'zindex-mess'})
        this.assiLoading = false
        return false
      }
      if(this.auditResult.indexOf('&') > -1) {
        const valueList = this.auditResult.split("&")
        const index = valueList[1]
        params['expertId'] = this.selectAudits[index].id+''
        params['state'] = valueList[0]
      } else {
        if(this.auditResult == 10) {
          params['expertId'] = this.getIds()
        }
        params['state'] = this.auditResult
      }
      if(!this.inputValue) {
        Message({type: 'error', message: "请输入审核意见", customClass: 'zindex-mess'})
        this.assiLoading = false
        return false
      }
      params['desc'] = this.inputValue
      const res = await assign(params)
      if(res.code === 0) {
        Message({type: 'success', message: "提交成功", customClass: 'zindex-mess'})
        this.assiLoading = false
        this.layout = ''
        this.audit = ''
        this.auditData = []
        this.selectAudits = []
        this.auditId = ''
        this.auditName = ''
        this.auditNum = ''
        this.auditResult = ''
        this.inputValue = ''
        this.getCheckData(this.contentId)
      } else {
        Message({type: 'error', message: res.msg || '提交失败', customClass: 'zindex-mess'})
        this.assiLoading = false
      }
    },
    // 获取所有选择专家id
    getIds() {
      const list = this.selectAudits
      let ids = ''
			for(let i=0; i<list.length; i++) {
				if(i < list.length - 1) {
					ids = ids + list[i].id + ','
				} else {
					ids = ids + list[i].id
				}
			}
			return ids
    },
    // 初审专家提交
    async editState() {
      if(!this.ideaResult) {
        Message({type: 'error', message: "请选择审核意见", customClass: 'zindex-mess'})
        return false
      }
      if(!this.inputValue) {
        Message({type: 'error', message: "请输入审核意见", customClass: 'zindex-mess'})
        return false
      }
      this.editLoading = true
      const params = {
        contentId: this.contentId,
        state: this.ideaResult === 1 ? 0 : (this.ideaResult === 2 ? 1 : 10 ),
        desc: this.inputValue,
        score: {}
      }
      // 初审专家增加临床评分
      if (this.userRole == 4) {
        this.scoreTableList.forEach(item => {
          params.score[item.key] = item.score
        })
      }
      const res = await editState(params)
      if(res.code === 0) {
        Message({type: 'success', message: "提交成功", customClass: 'zindex-mess'})
        this.editLoading = false
        this.getCheckData(this.contentId)
         this.getInfo(this.contentId)
      } else {
        Message({type: 'error', message: res.msg || '提交失败', customClass: 'zindex-mess'})
        this.editLoading = false
      }
    },
    // 下载文件
    downloadFile(url) {
      if(!url) {
        Message({type: 'error', message: "没有文件路径", customClass: 'zindex-mess'})
        return false
      }
      window.top.location.href = url
    },
    // 返回上一级
    callBack() { this.$router.go(-1) }
  }
}
</script>

<style lang="less" scoped>
.check-detail {
  width: 100%;
  padding-bottom: 8px;
  font-size: 16px;
  font-family: Helvetica;
  color: #333333;
  position: relative;

  .back {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .box {
    width: 100%;
    margin-top: 32px;
    background-color: #fff;
    position: relative;

    .upload-box {
      position: absolute;
      top: 20px;
      left: 150px;
    }

    .code {
      position: absolute;
      top: 20px;
      left: 150px;
      line-height: 40px;
      color: #1A70FC;
    }
  }

  .list, .basic, .record, .action, .log {
    width: 100%;
    box-sizing: border-box;
  }
  .list {
    padding: 20px;

    .el-col  {
      padding: 10px;
    }

    .name {
      color: #9c9c9c;
    }
  }

  .basic {
    padding: 20px 30px 30px;
    color: #6D6D6D;
    line-height: 28px;

    h2 {
      font-size: 18px;
      padding: 8px 0;
    }

    .title {
      color: #2c313b;
    }

    .author {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      p {
        margin-right: 20px;
      }
    }

    .digest {
      color: #4A79FB;
    }

    .text {
      white-space: pre-line;

      .text-btn {
        font-size: 18px;
        text-align: right;
        color: #66b1ff;
        cursor: pointer;
      }
    }
  }

  .record {
    padding: 30px;
  }

  .action {
    padding: 20px 20px 32px;

    .action-div {
      width: 100%;
      padding-bottom: 8px;
      min-height: 40px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    .action-select {
      .el-button,
      .el-tag,
      .el-select,
      p {
        margin-bottom: 5px;
      }
    }

    .radio-div {
      p, .el-radio {
        margin-bottom: 10px;
      }
    }

    .action-input {
      margin-top: 10px;
    }

    .el-button {
      margin-left: 10px;
    }

    .el-tag {
      margin-left: 10px;
    }

    .btn {
      width: 100px;
      margin: 32px auto;

      .el-button {
        width: 100%;
      }
    }
  }

  .log {
    padding: 20px;
    min-height: 200px;

    .log-item {
      margin-bottom: 15px;
      display: flex;
      align-items: center;
    }

    .image {
      width: 30px;
      height: 30px;
      margin-right: 30px;
      position: relative;

      img {
        width: 100%;
        height: 100%;
      }

      .line {
        width: 3px;
        background-color: #F9CB00;
        position: absolute;
        top: -10%;
        left: 50%;
        transform: translate(-50%, -100%);
      }
      .wait {
        background-color: #CFCFCF;
      }
    }

    .steps {
      width: calc(100% - 60px);
      background: #f6f9ff;
      border-radius: 4px;
      padding: 13px 0 26px 0;
      box-sizing: border-box;
      font-family: PingFangSC, PingFangSC-Regular;
      color: rgba(0,0,0,0.65);
      line-height: 20px;
      position: relative;

      li {
        width: 100%;
        padding: 13px 200px 0 26px;
        box-sizing: border-box;
      }

      .first {
        display: flex;
        align-items: center;
        p {
          width: 400px;
        }
      }

      .title {
        font-weight: 700;
        color: rgba(0,0,0,0.85);
      }

      .result {
        width: 78px;
        height: 60px;
        position: absolute;
        top: 50%;
        right: 100px;
        margin-top: -30px;
      }
    }
  }
  .exam-result{
    width: 100%;
    border-color: #fff;
  }
  .exam-result td.default-td{
    background-color: #fff;
  }
  .exam-result tr.active, .exam-result td.active{
    background-color: #f2f2f2;
  }
  .exam-result td{
    min-width: 100px;
    font-size: 14px;
    padding: 10px;
    text-align: center;
    color: #000;
  }
  .exam-result td.weight-td{
    font-weight: bold;
  }
  .exam-result td.tag-td{
    font-weight: bold;
    color: #ff0000;
  }
}
</style>
