<template>
  <div class="audit-list">
		<header-title title="审核历史" :border="true" />
    <!-- 有数据 -->
    <template v-if="data.length">
      <!-- 列表 -->
      <div class="table-box">
        <el-table :data="data" border stripe style="width: 100%">
          <el-table-column label="序号" align="center">
            <template slot-scope="scope">{{ getNumId(scope.$index) }}</template>
          </el-table-column>
          <el-table-column prop="uid" label="编号" align="center" />
          <el-table-column prop="title" label="标题" width="300" />
          <el-table-column prop="creatorName" label="投稿医师" align="center" />
          <el-table-column prop="editorName" label="责任编辑" align="center" />
          <el-table-column prop="expertName" label="初审专家" align="center" />
          <el-table-column prop="createTime" label="入驻时间" align="center" />
          <el-table-column label="操作" width="150" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="openDetail(scope.row.id)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="footer-page">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="pageChange"
          :total="total"
          :page-size="10"
          :current-page="page">
        </el-pagination>
      </div>
    </template>
    <!-- 无数据 -->
    <template v-else>
      <div class="empty"><el-empty :image-size="200"></el-empty></div>
    </template>
  </div>
</template>

<script>
import HeaderTitle from '../HeaderTitle.vue'
import { getUserAuditLog } from '@/api/case'

export default {
  name: 'AuditList',
  components: { HeaderTitle },
  props: {
		userRole: {
      type: Number | String,
      default: ''
    },
    userId: {
      type: Number | String,
      default: ''
    }
  },
  data () {
    return {
      data: [],
      total: 0,
      page: 1,
    }
  },
  watch: {
    userId: {
      handler(newUserId, oldUserId){
        this.getDate(newUserId, 1)
      },
      deep: true,
      immediate: true
    }
  },
  created () {
    this.getDate(this.userId, 1)
  },
	methods: {
    // 获取审批数据
    async getDate(id, page) {
      const params = {
        userId: id,
        pageNum: page,
        pageSize: 10,
      }
      const res = await getUserAuditLog(params)
      if(res.code === 0) {
        const list = res.data && res.data.list ? res.data.list : {}
        this.data = list.rows ? list.rows : []
        this.total = list.total ? list.total : 0
        this.page = page
      }
    },

    // 获取序号
    getNumId(index) {
      const num1 = index + 1
      const num2 = (this.page - 1) * 10
      return Number(num1 + num2)
    },

    // 分页
    pageChange(page) {
      this.getDate(this.userId, page)
    },

    // 查看稿件详情
		openDetail(id) {
			this.$router.push({
        path: '/check-detail',
        query: { id }
      });
		}
	}
}
</script>

<style lang="less" scoped>
.audit-list {
	width: 100%;
	margin-top: 32px;
	box-sizing: border-box;
	background-color: #fff;

	.table-box {
    margin-top: 32px;
		padding: 0 52px 32px;
	}

  .empty {
    width: 100%;
    margin-top: 32px;
    background-color: #fff;
  }
}
</style>
