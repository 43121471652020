<template>
	<div class="state-count">
		<div class="state" v-for="(item, key, index) of count" :key="index">
			<img alt="" :src="info[key].img" />
			<div>
				<h3>{{info[key].title}}</h3>
				<h2>{{item}}</h2>
			</div>
		</div>
	</div>
</template>

<script>
import state1 from "../assets/image/state1.png"
import state2 from "../assets/image/state2.png"
import state3 from "../assets/image/state3.png"
import state4 from "../assets/image/state4.png"
import state5 from "../assets/image/state5.png"

export default {
  name: 'StateCount',
  props: {
    count: Object
  },
	data() {
    return {
      info: {
        pendingNum: {
          title: '在审',
          img: state1
        },
        firstAuditNum: {
          title: '初审通过',
          img: state2
        },
        lastAuditRejectNum: {
          title: '终审未通过',
          img: state3
        },
        lastAuditPassNum: {
          title: '终审通过',
          img: state4
        },
        refuseNum: {
          title: '退稿',
          img: state5
        },
      }
    }
  },
}
</script>

<style lang="less" scoped>
.state-count {
	width: 100%;
	margin-top: 32px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #fff;
	padding: 22px 52px 32px;
	box-sizing: border-box;
	font-size: 16px;
	font-family: PingFangSC, PingFangSC-Regular;
	color: #0b1e3b;

	.state {
		width: 260px;
		height: 140px;
		position: relative;

		img {
			width: 100%;
			height: 100%;
		}

		div {
			width: 110px;
			position: absolute;
			left: 52%;
			top: 50%;
			margin-top: -30px;
		}

		h3 {
			font-size: 16px;
		}

		h2 {
			font-size: 34px;
		}
	}
}
</style>