import request from '@/utils/request'

// 初审专家审批稿件
export function editState (params) {
	return request.post('/contribute-web/auditLog/editState', params)
}

// 获取稿件审批日志
export function getAuditLog (params) {
	const { contentId } = params
	return request.get('/contribute-web/auditLog/getAuditLogByContentId?contentId='+contentId)
}

// 查看用户审批列表
export function getUserAuditLog (params) {
	return request.post('/contribute-web/auditLog/getAuditLogByUserId', params)
}

// 获取所有审核列表
export function getCheckList (params) {
  return request.post('/contribute-web/content/showList', params)
}

// 责任编辑操作稿件
export function assign (params) {
	return request.post('/contribute-web/content/assign', params)
}

// 获取稿件基本信息
export function getContent (params) {
	const { id } = params
	return request.get('/contribute-web/content/getContent?id='+id)
}

// 获取上传文档列表
export function getContentFile (params) {
	const { contentId } = params
	return request.get('/contribute-web/content/file/getContentFile?contentId='+contentId)
}

// 添加文档
export function addContentFile (params) {
	return request.post('/contribute-web/content/file/addContentFile', params)
}

// 替换文档
export function updContentFile (params) {
	return request.post('/contribute-web/content/file/updateContentFile', params)
}

// 删除文档
export function delContentFile (params) {
	const { id } = params
	return request.get('/contribute-web/content/file/delContentFile?id='+id)
}

// 催办 
export function urgeUser (params) {
	const { contentId } = params
	return request.get('/contribute-web/user/send?contentId='+contentId)
}

// 获取评分列表
export function getScoreList (params) {
	const { contentId } = params
	return request.get('/contribute-web/score/list?contentId='+contentId)
}

// 单个导出稿件文档和审批日志
export function exportContent (params) {
	const { contentId } = params
	return request.get('/contribute-web/content/exportContent?contentId='+contentId)
}

// 批量导出稿件文档和审批日志
export function batchExport (params) {
	const { contentIds } = params
	return request.get('/contribute-web/content/batchExport?contentIds='+contentIds)
}
