<template>
  <div class="audit-add">
    <header-title :title="type ? '修改初审专家' : '新建初审专家'" />
	<div class="back">
      	<el-button type="primary" @click="callBack">返回</el-button>
    </div>

	<div class="content">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
			<el-form-item label="姓名：" prop="name">
				<el-input v-model="ruleForm.name" placeholder="请输入姓名"></el-input>
			</el-form-item>
			<el-form-item label="科室：" prop="depart">
				<el-input v-model="ruleForm.depart" placeholder="请输入科室"></el-input>
			</el-form-item>
			<el-form-item label="执业机构：" prop="hosName">
				<el-input v-model="ruleForm.hosName" placeholder="请输入执业机构"></el-input>
			</el-form-item>
			<el-form-item label="学术职称：" prop="academic">
				<el-select v-model="ruleForm.academic" placeholder="请选择学术职称">
					<el-option 
					v-for="(item,index) in acadOption" 
					:key="index"
					:label="item.name"
					:value="item.id">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="临床职称：" prop="prof">
				<el-select v-model="ruleForm.prof" placeholder="请选择临床职称">
					<el-option 
					v-for="(item,index) in profOption" 
					:key="index"
					:label="item.name"
					:value="item.id">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="行政职务：" prop="duty">
				<el-input v-model="ruleForm.duty" placeholder="请输入行政职务"></el-input>
			</el-form-item>
			<div v-for="(item, index) in ruleForm.class" :key="index">
				<el-form-item 
				:label="index === 0 ? '标准科室：' : ''" 
				:rules="{type: 'array', required: true, message: '没有标准科室'}"
				>
				<el-col :span="index === ruleForm.class.length -1 ? 20 : 24">
					<el-form-item
					:prop="'class.' + index + '.value'" 
					:rules="classLlist.length ? {} : {required: true, message: '请选择标准科室', trigger: 'change'}">
						<el-select 
						v-model="item.value" 
						placeholder="请选择标准科室" 
						@change="selectChange($event, index)">
							<el-option
								v-for="i in item.list"
								:key="i.id"
								:label="i.name"
								:value="i.id +'&'+ i.name">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col v-if="index === ruleForm.class.length -1" :span="4">
					<el-button
					class="add-btn"
					@click="addLevel" 
					type="primary">
						添加
					</el-button>
				</el-col>
				</el-form-item>
			</div>
			<el-form-item>
				<p>请谨慎选择标准科室，将根据标准科室自动关联责任编辑。</p>
			</el-form-item>	
			<el-form-item v-if="classLlist.length">
				<el-tag
					v-for="(tag, index) in classLlist"
					:key="index"
					effect="dark"
					@close="deletelevel(index)"
					closable>
					{{tag.name}}
				</el-tag>
			</el-form-item>
			<el-form-item label="擅长疾病：" prop="domain">
				<el-input v-model="ruleForm.domain" placeholder="请输入擅长疾病"></el-input>
			</el-form-item>
			<el-form-item label="电子邮箱：" prop="userEmail">
				<el-input v-model="ruleForm.userEmail" placeholder="请输入电子邮箱"></el-input>
			</el-form-item>
			<el-form-item label="手机号码：" prop="phone">
				<el-input v-model="ruleForm.phone" placeholder="请输入手机号码"></el-input>
			</el-form-item>
			<el-form-item>
				<p>请输入正确手机号，专家将通过手机号验证码登录系统。</p>
			</el-form-item>
			<el-form-item>
				<el-button class="submit" type="primary" @click="submitForm('ruleForm')">确定</el-button>
			</el-form-item>
		</el-form>
	</div>
  </div>
</template>

<script>
import HeaderTitle from '../../components/HeaderTitle.vue'
import { getDict, getUserDetail, addUser, updateUser } from '@/api/user'

export default {
  name: 'AuditAdd',
  components: { HeaderTitle },
	data() {
		return {
			type: null,
			userId: null,
			profOption: [],
			acadOption: [],
			classLlist: [],
			classId: null,
			className: null,
			ruleForm: {
				name: '',
				hosName: '',
				depart: '',
				academic: '',
				prof: '',
				duty: '',
				class: [],
				domain: '',
				phone: '',
				userEmail: '',
			},
			rules: {
				name: this.getRegu('姓名'),
				hosName: this.getRegu('执业机构'),
				depart: this.getRegu('科室'),
				academic: [
					{ required: true, message: '请选择学术职称', trigger: 'change' }
				],
				prof: [
					{ required: true, message: '请选择临床职称', trigger: 'change' }
				],
				duty: this.getRegu('行政职务'),
				domain: this.getRegu('擅长疾病'),
				phone: [
					{ required: true, message: '请输入手机号码', trigger: 'blur' },
					{ pattern: /^(1[0-9]{10})$/, message: '请输入正确的手机号码', trigger: 'blur' }
				],
				userEmail: [
					{ required: true, message: '请输入电子邮箱', trigger: 'blur' },
					{ max: 50, message: '最多输入50个字符', trigger: 'blur' },
					{ pattern: /^[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/, message: '请输入正确的电子邮箱', trigger: 'blur' }
				]
			}
		}
	},
  	async created () {
    	const query = this.$route.query;
		const type = query.type - 0 || 0
		this.type = type
		this.userId = query.userId
		if(type) {
			await this.getInfo(query.userId)
		}
		this.userClass()
		this.getDict()
		this.getAcadDict()
  	},
	methods: {
		// 正则校验
		getRegu(name) {
			return [
				{ required: true, pattern: /[^ \x22]+/, message: '请输入'+name, trigger: 'blur' },
				{ required: true, message: '请输入'+name, trigger: 'blur' },
				{ max: 200, message: '最多输入200个字符', trigger: 'blur' },
				// { pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+$/, message: '不允许输入空格等特殊符号' },
			]
		},

		// 当前标准科室
		userClass() {
			const userRole = sessionStorage.getItem('userRole') || ''
			const userClassArrStr = sessionStorage.getItem('userClassArrStr') || ''
			const userClassArr = userClassArrStr ? JSON.parse(userClassArrStr) : []
			if(userRole != 1) {
				const list = userClassArr.length ? userClassArr : [{id: 0, name: '暂无标准科室'}]
				if(list.length) {
					this.ruleForm.class.push({
						value: '',
						list
					})
				}
			} else {
				this.getclassLlist()
			}
		},

		// 获取 审核专家信息
		async getInfo(id) {
			const res = await getUserDetail({id})
      		if(res.code === 0) {
				const info = res.data || {}
				this.getClassArr(info.classId, info.className)
        		this.ruleForm = {
					name: info.name || '',
					hosName: info.hosName || '',
					depart: info.depart || '',
					academic: info.academic || '',
					prof: info.prof || '',
					duty: info.duty || '',
					class: [],
					domain: info.domain || '',
					phone: info.phone || '',
					userEmail: info.userEmail || '',
				}
      		}
		},

		getClassArr(ids, names) {
			const idArr = ids ? ids.split(',') : []
			const nameArr = names ? names.split(',') : []
			const list = []
			for(let i=0; i<nameArr.length; i++) {
				list.push({
					id: idArr[i],
					name: nameArr[i]
				})
			}
			this.classLlist = list
		},

		// 获取学术职称
		async getAcadDict() {
			const params = { pid: '', type: 30 }
			const res = await getDict(params)
			if(res.code === 0) {
				this.acadOption = res.data
			}
		},

		// 获取临床职称
		async getDict() {
			const params = { pid: '', type: 5 }
			const res = await getDict(params)
			if(res.code === 0) {
				this.profOption = res.data
			}
		},

		// 获取科室列表
		async getclassLlist(id) {
			const params = { pid: id ? id : '', type: 19 }
			const res = await getDict(params)
			if(res.code === 0) {
				const list = res.data || [{id: 0, name: '暂无标准科室'}]
				if(list.length) {
					this.ruleForm.class.push({
						value: '',
						list
					})
				}
			}
		},

		// 标准科室
		selectChange(value, index) {
			const valueList = value.split("&")
      		this.classId = valueList[0]
      		this.className = valueList[1]
			this.ruleForm.class = this.ruleForm.class.slice(0, ++index);
			if(value) {
				this.getclassLlist(this.classId)
			}
		},

		// 添加标准科室
		addLevel() {
			const list = this.ruleForm.class
			const value = list[list.length -1].value
			const params = { id: this.classId, name: this.className }
			if(!value){
				this.$message({type: 'error', message: '请选择标准科室', customClass: 'zindex-mess'})
				return false
			}
			const index = this.deleteRepete(this.classLlist, "id", params.id);
			if (index < 0) {
				this.classLlist.push(params);
			} else {
				this.$message({type: 'error', message: '该分类已添加', customClass: 'zindex-mess'})
			}
		},

		// 数组去重
		deleteRepete(list, key, value) {
			for (let i = 0; i < list.length; i++) {
				if (list[i][key] === value) { return i; }
			}
			return -1;
		},

		// 删除标准科室
		deletelevel(index) {
			this.classLlist.splice(index, 1)
		},

		// 提交
		submitForm(formName) {
			const data = this.classLlist
			const params = {
				name: this.ruleForm.name,
				hosName: this.ruleForm.hosName,
				depart: this.ruleForm.depart,
				academic: this.ruleForm.academic,
				prof: this.ruleForm.prof,
				duty: this.ruleForm.duty,
				classId: this.getClassIds(),
				domain: this.ruleForm.domain,
				phone: this.ruleForm.phone,
				userEmail: this.ruleForm.userEmail,
				role: 4,
			}
			if(this.type && this.userId) { params.id = this.userId }

			this.$refs[formName].validate((valid) => {
				if(!valid) { return false; }
				if(!data.length){
					this.$message({type: 'error', message: '请添加标准科室', customClass: 'zindex-mess'})
					return false
				}
				if(this.type) {
					this.updateUser(params)
				} else {
					this.addUser(params)
				}
			});
		},

		// 新增
		async addUser(params) {
			console.log('\n\n---params---', params)
			const res = await addUser(params)
			if(res.code === 0) {
				this.$router.push({ path: '/audit' });
			} else {
				this.$message({type: 'error', message: res.msg, customClass: 'zindex-mess'})
			}
		},

		// 编辑
		async updateUser(params) {
			console.log('\n\n---params---', params)
			const res = await updateUser(params)
			if(res.code === 0) {
				this.$router.push({ path: '/audit' });
			} else {
				this.$message({type: 'error', message: res.msg, customClass: 'zindex-mess'})
			}
		},

		// 获取标准科室id
		getClassIds() {
			const list = this.classLlist
			let ids = ''
			for(let i=0; i<list.length; i++) {
				if(i < list.length - 1) {
					ids = ids + list[i].id + ','
				} else {
					ids = ids + list[i].id
				}
			}
			return ids
		},

		// 返回上一级
    	callBack() { this.$router.go(-1) }
	}
}
</script>

<style lang="less" scoped>
.audit-add {
  width: 100%;
  height: 100%;
	position: relative;
}

.back {
  position: absolute;
  top: 20px;
  right: 20px;
}

.content {
	width: 100%;
	margin-top: 32px;
	padding: 50px 0 20px;
	background-color: #fff;
}

::v-deep.el-form {
	width: 800px;
	margin: 0 auto;
	.el-form-item {
		.el-form-item__label, p {
			font-size: 16px;
		}

		.el-select {
			width: 100%;
		}

		.level {
			width: 80%;
		}
		.add-btn {
			width: 80px;
			float: right;
		}

		.el-tag {
			height: 36px;
			line-height: 36px;
			font-size: 16px;
			margin-right: 10px;
		}

		.submit {
			width: 120px;
			margin: 50px 0 0 180px;
		}
	}
}
</style>
