<template>
  <div class="error">
		<el-empty description="抱歉，请求错误 404"></el-empty>
  </div>
</template>

<script>
export default {
  name: 'Error',
}
</script>

<style lang="less" scoped>
.error {
  width: 100%;
  height: 100%;
	padding-top: 100px;
  box-sizing: border-box;
  background-color: #fff;

	::v-deep.el-empty {
		.el-empty__description p {
			font-size: 26px;
			color: #a2a2a2;
		}
	}
}
</style>
