<template>
  <div class="audit-detail">
    <header-title title="初审专家详情" />
    <div class="back">
      <el-button type="primary" @click="callBack">返回</el-button>
    </div>
    <user-info title="初审专家信息" :info="userInfo" :checkUserRole="4" />
    <state-count :count="count" />
    <audit-list :userId="userId" :userRole="userRole" />
  </div>
</template>

<script>
import HeaderTitle from '../../components/HeaderTitle.vue'
import UserInfo from '../../components/user/UserInfo.vue'
import StateCount from '../../components/StateCount.vue'
import AuditList from '../../components/user/AuditList.vue'
import { getUserDetail } from '@/api/user'
import { getUserAuditLog } from '@/api/case'

export default {
  name: 'AuditDetail',
  components: { HeaderTitle, UserInfo, StateCount, AuditList },
  data() {
    return {
      userInfo: {},
      count: {
        pendingNum: 0,
        firstAuditNum: 0,
        lastAuditRejectNum: 0,
        lastAuditPassNum: 0,
        refuseNum: 0,
      },
      userId: '',
      userRole: '',
    }
  },
  created () {
    this.userRole = sessionStorage.getItem('userRole')
    const query = this.$route.query;
    this.userId = query.userId
    if(query.userId) {
      this.getUserDetail(query.userId)
      this.getDate(query.userId)
    }
  },
  methods: {
    // 获取用户信息
    async getUserDetail(id) {
      const res = await getUserDetail({id})
      if(res.code === 0) {
        this.userInfo = res.data
      }
    },

    // 获取审批数据
    async getDate(id) {
      const params = { userId: id }
      const res = await getUserAuditLog(params)
      if(res.code === 0) {
        this.count = {
          pendingNum: res.data.pendingNum || 0,
          firstAuditNum: res.data.firstAuditNum || 0,
          lastAuditRejectNum: res.data.lastAuditRejectNum || 0,
          lastAuditPassNum: res.data.lastAuditPassNum || 0,
          refuseNum: res.data.refuseNum || 0,
        }
      }
    },
    // 返回上一级
    callBack() { this.$router.go(-1) }
  }
}
</script>

<style lang="less" scoped>
.audit-detail {
  width: 100%;
  height: 100%;
	position: relative;
}

.back {
  position: absolute;
  top: 20px;
  right: 20px;
}
</style>
