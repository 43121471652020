import Vue from 'vue'
import { login as userLogin, logout as userLogout } from '@/api/auth'
import { ACCESS_TOKEN, USER } from '@/store/mutation-types'

const user = {
  namespaced: true,
  state: {
    token: '',
    user: null
  },
  getters: {
    username (state) {
      return (state.user && state.user.username) || ''
    }
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      Vue.ss.set(ACCESS_TOKEN, token, 7 * 24 * 60 * 60 * 1000)
      state.token = token
    },
    SET_USER: (state, user) => {
      Vue.ss.set(USER, user)
      state.user = user
    }
  },

  actions: {
    // 登录
    Login ({ commit, dispatch }, userInfo) {
      return new Promise(async (resolve, reject) => {
        const res = await userLogin(userInfo)
        if (res.code === 0) {
          const menus = res.data.modules || []
          commit('SET_TOKEN', 1)
          commit('SET_USER', res.data.userId)
          commit('permission/SET_TOPMENUS', menus, {
            root: true
          })

          if (menus.length > 0) {
            menus.forEach(item => {
              console.log(item)
              item.children = item.menu
              item.url = null
            })
            commit('permission/SET_MENUS', menus[0].children, {
              root: true
            })
            resolve(res)
          } else {
            this.$message({type: 'error', message: '没有配置菜单权限', customClass: 'zindex-mess'})
            reject(res)
          }
        } else {
          reject(res)
        }
      })
    },
    Logout ({ commit, state }) {
      return new Promise(resolve => {
        userLogout()
        commit('SET_TOKEN', '')
        Vue.ss.remove(ACCESS_TOKEN)
        resolve()
      })
    }
  }
}

export default user
